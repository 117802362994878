import { useState } from 'react';

import { PlaceOutlined } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';

import { useLocale } from '../../../../src/hooks/locale';
import { PlacePicker } from '../../place-picker/PlacePicker';
import { useFiltersSearchParams } from '../useFiltersSearchParams';
import { useSchema } from '../useSchema';

import { FilterChip } from './FilterChip';
import { type QuickFilterProps } from './QuickFilters';

export const PlaceFilter = ({
  label,
  path,
  where,
  addWhereClause,
  deleteWhereClause,
}: QuickFilterProps) => {
  const { t } = useLocale();
  const [, setFiltersParams] = useFiltersSearchParams();
  const { getValueFromPath } = useSchema();
  const defaultValue = getValueFromPath(path, where)?.id?._in ?? [];
  const [value, setValue] = useState<string[]>(defaultValue);

  const onChange = (value: string[]) => {
    let newWhere = deleteWhereClause(where, path, true);
    if (value.length > 0) {
      newWhere = addWhereClause(newWhere, [...path, 'id', '_in'], value);
    }
    setFiltersParams(newWhere);
  };

  const displayLabel =
    defaultValue.length > 0 ? `${label} (${defaultValue.length})` : label;
  return (
    <FilterChip
      label={displayLabel}
      icon={<PlaceOutlined />}
      onDelete={defaultValue.length > 0 ? () => onChange([]) : undefined}
      renderFilter={({ handleClose }) => (
        <Dialog
          open
          onClose={handleClose}
          fullWidth
          maxWidth="xl"
          PaperProps={{
            sx: {
              height: '100%',
            },
          }}
        >
          <DialogTitle sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <PlaceOutlined />
            {label}
          </DialogTitle>
          <DialogContent
            sx={{
              paddingBottom: 0,
              paddingX: { xs: 1, md: 3 },
            }}
          >
            <PlacePicker defaultValue={defaultValue} onChange={setValue} />
          </DialogContent>
          <DialogActions sx={{ padding: 2 }}>
            <Button onClick={handleClose} variant="outlined">
              {t('Cancel')}
            </Button>
            <Button
              onClick={() => {
                onChange(value);
                handleClose();
              }}
              variant="contained"
              disableElevation
            >
              {t('Apply')}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    />
  );
};
