import React, { Suspense, lazy, useState } from 'react';

import type { ApolloError } from '@apollo/client';
import { useMutation, useQuery } from '@apollo/client';
import {
  ArrowBack,
  ArrowForward,
  Delete,
  GridViewRounded,
  Search,
  TableRows,
} from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Box,
  Card,
  CardActionArea,
  Divider,
  Grid,
  Pagination,
  PaginationItem,
  Skeleton,
  Stack,
  Toolbar,
  Typography,
  useMediaQuery,
} from '@mui/material';
import type { Theme } from '@mui/material/styles';
import type {
  GridColDef,
  GridRowSelectionModel,
  GridRowsProp,
} from '@mui/x-data-grid-premium';
import { GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid-premium';
import {
  Link,
  Route,
  Routes,
  useMatch,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';

import { ListToolbar } from '../../../list-toolbar/ListToolbar';
import { toGlobalId } from '../../../shared/global-id';
import { NavigationPortal } from '../../../src/components/Navigation';
import { useLocale } from '../../../src/hooks/locale';
import { LotCreateDrawer } from '../../../src/shared/lot-create-drawer';
import { TopbarTitle } from '../../../src/shared/topbar';
import { lazyRetry } from '../../../src/utils/lazy';
import type {
  DeleteLotsMutation,
  GetLotsListCountQuery,
  GetLotsListQuery,
  Lots_Bool_Exp,
  TogglePortalListingMutation,
} from '../../__generated__/graphql';
import { ListingCell } from '../../components/data-grid/ListingCell';
import { ListingPriceCell } from '../../components/data-grid/ListingPriceCell';
import { RaDataGrid } from '../../components/data-grid/RaDataGrid';
import { UserInfo } from '../../components/data-grid/UserInfo';
import { LoadingCheckBox } from '../../components/LoadingCheckbox';
import { LotCardCarousel } from '../../components/LotCardCarousel';
import { LotCardInfo } from '../../components/LotCardInfo';
import { LotSelect } from '../../components/LotsSelect';
import { MutationErrorModal } from '../../components/MutationErrorModal';
import { Tag } from '../../components/Tag';
import { TimeAgo } from '../../components/TimeAgo';
import { UserChip } from '../../components/UserChip';
import { useAppData } from '../../providers/AppDataProvider';

import { CreateListingDrawer } from './CreateListingDrawer';
import { ListingsExport } from './ListingsExport';
import ListingsImport from './ListingsImport';
import {
  DELETE_LOTS,
  GET_LOTS_LIST,
  GET_LOTS_LIST_COUNT,
  TOGGLE_PORTAL_LISTING,
} from './lotsQueries';

const ListingDrawer = lazy(() => lazyRetry(() => import('./ListingDrawer')));
const LegacyListingDrawer = lazy(() =>
  lazyRetry(() => import('./LegacyListingDrawer')),
);

const GRID_PAGE_SIZE = 48;
const LIST_PAGE_SIZE = 100;

const tableFilters = [
  {
    name: 'lots',
    label: 'Lots',
    relationshipPath: null,
    fields: { table: 'lots' },
  },
  {
    name: 'listings',
    label: 'Listings',
    relationshipPath: 'portal_listings',
    fields: { table: 'portal_listings' },
  },
  {
    name: 'leads',
    label: 'Leads',
    relationshipPath: 'lead',
    fields: { table: 'leads' },
  },
  {
    name: 'offers',
    label: 'Offers',
    relationshipPath: 'offers',
    fields: { table: 'offers' },
  },
  {
    name: 'portal_listings',
    label: 'Listings',
    relationshipPath: 'portal_listings',
    fields: { table: 'portal_listings' },
  },
];

const quickFilters = (t: any) => [
  {
    label: t('Created'),
    path: ['lots_bool_exp', 'created_at'],
  },
  {
    label: t('Signed'),
    path: ['lots_bool_exp', 'signed_at'],
  },
  { label: t('Status'), path: ['lots_bool_exp', 'status', '_in'] },
  {
    label: t('Source'),
    path: ['lots_bool_exp', 'origin', 'id', '_in'],
    displayedColumn: 'label',
    filter: {
      path: ['name', 'type', '_in'],
      value: 'lot_origin_types',
    },
  },
  {
    label: t('Agent'),
    path: ['lots_bool_exp', 'broker'],
  },
  {
    label: t('Seller'),
    path: ['lots_bool_exp', 'sellers', 'seller'],
  },
  {
    label: t('Team'),
    path: ['lots_bool_exp', 'broker', 'teams_users', 'team'],
  },
  {
    label: t('Location'),
    path: ['lots_bool_exp', 'property', 'places'],
  },
  {
    label: t('Price'),
    path: ['lots_bool_exp', 'sale_price'],
  },
  {
    label: t('Online'),
    path: ['lots_bool_exp', 'portal_listings', 'live'],
  },
  {
    label: t('Buy / Rent'),
    path: ['lots_bool_exp', 'offer_type', '_in'],
  },
  {
    label: t('Property type'),
    path: ['lots_bool_exp', 'property', 'property_type', 'name', '_in'],
    displayedColumn: 'label',
    filter: {
      path: ['property_type', 'main_type', '_is_null'],
      value: false,
    },
  },
  {
    label: t('Rooms'),
    path: ['lots_bool_exp', 'property', 'number_of_rooms'],
  },
  {
    label: t('Offers'),
    path: ['lots_bool_exp', 'offers_aggregate', 'count', 'predicate'],
  },
  {
    label: t('Enquiries'),
    path: ['lots_bool_exp', 'enquiries_aggregate', 'count', 'predicate'],
  },
  {
    label: t('Exclusivity'),
    path: ['lots_bool_exp', 'is_exclusive'],
  },
  {
    label: t('Time to sell'),
    path: ['lots_bool_exp', 'expected_time_to_sell', '_in'],
  },
];

type Lot = GetLotsListQuery['lots'][number];

const LotCard = React.memo(
  ({
    data,
    listingLink,
  }: {
    data: Lot;
    listingLink: (id: string) => { pathname: string; search: string };
  }) => {
    const { t } = useLocale();

    const { sellers, broker } = data;
    const seller = sellers[0]?.seller;

    return (
      <Link to={listingLink(data.id as string)} css={{ height: '100%' }}>
        <Card
          sx={{
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
            height: '100%',
          }}
        >
          <LotCardCarousel lot={data} />

          <CardActionArea sx={{ flexGrow: 1 }}>
            <Stack direction="column" flex={1} height="100%">
              <Box p={2} flex={1} height="100%">
                <LotCardInfo
                  lot={data}
                  showTitle={true}
                  showInlineLabels={true}
                />
              </Box>
              <Divider sx={{ opacity: 0.5 }} />

              <Stack
                p={2}
                direction="row"
                justifyContent="space-between"
                height="83.91px"
                spacing={2}
              >
                {seller != null && (
                  <Stack minWidth={0}>
                    <Typography variant="caption">{t('seller')}</Typography>
                    <UserChip user={seller} />
                  </Stack>
                )}

                {broker != null && (
                  <Stack minWidth={0}>
                    <Typography variant="caption">{t('agent')}</Typography>
                    <UserChip user={broker} />
                  </Stack>
                )}
              </Stack>
            </Stack>
          </CardActionArea>
        </Card>
      </Link>
    );
  },
);

const LotCardSkeleton = () => {
  return (
    <Card>
      <Skeleton
        variant="rectangular"
        sx={{
          paddingTop: '56.25%',
        }}
        height="30%"
        width="100%"
      />
      <CardActionArea sx={{ flexGrow: 1 }}>
        <Stack direction="column" flex="1">
          <Box p={2}>
            <Stack spacing={1}>
              <Skeleton variant="rounded" width={230} height={14} />
              <Skeleton variant="rounded" width={230} height={18} />
              <Skeleton variant="rounded" width={160} height={24} />
              <Skeleton variant="rounded" width={80} height={16} />
              <Stack spacing={1} direction="row">
                <Skeleton variant="rounded" width={70} height={22} />
                <Skeleton variant="rounded" width={80} height={22} />
              </Stack>
            </Stack>
          </Box>
          <Divider sx={{ opacity: 0.5 }} />
          <Stack p={2} direction="row" justifyContent="space-between">
            <Stack spacing={0.8}>
              <Skeleton variant="rounded" width={40} height={14} />
              <Skeleton
                variant="rounded"
                width={100}
                height={32}
                sx={{
                  borderRadius: 16,
                }}
              />
            </Stack>
            <Stack spacing={0.8}>
              <Skeleton variant="rounded" width={40} height={14} />
              <Skeleton
                variant="rounded"
                width={100}
                height={32}
                sx={{
                  borderRadius: 16,
                }}
              />
            </Stack>
          </Stack>
        </Stack>
      </CardActionArea>
    </Card>
  );
};

const GridView = ({
  lots,
  totalCount,
  listingLink,
  loading,
}: {
  lots?: GetLotsListQuery['lots'];
  totalCount?: number;
  listingLink: (id: string) => { pathname: string; search: string };
  loading?: boolean;
}) => {
  const { t } = useLocale();
  const [searchParams, setSearchParams] = useSearchParams();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm'),
  );

  const offset = parseInt(searchParams.get('offset') ?? '0');
  const currentPage = Math.floor(offset / GRID_PAGE_SIZE) + 1;

  return (
    <Box flex={1}>
      <Box minHeight="100%">
        <Grid
          container
          spacing={2}
          p={2}
          justifyContent={
            !loading && lots && lots.length > 0 ? 'flex-start' : 'center'
          }
        >
          {!loading ? (
            lots && lots.length > 0 ? (
              lots.map(lot => (
                <Grid
                  item
                  key={lot.id}
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  xl={2}
                  display="flex"
                  direction="column"
                >
                  <LotCard data={lot} listingLink={listingLink} />
                </Grid>
              ))
            ) : (
              <Grid item>
                <Typography variant="body1" align="center">
                  {t('No results found')}
                </Typography>
              </Grid>
            )
          ) : (
            Array.from({ length: GRID_PAGE_SIZE }).map((_, index) => (
              <Grid
                item
                key={index}
                xs={12}
                sm={6}
                md={4}
                lg={3}
                xl={2}
                display="flex"
                direction="column"
              >
                <LotCardSkeleton />
              </Grid>
            ))
          )}
        </Grid>
      </Box>
      <Box
        sx={{
          position: 'sticky',
          bottom: 0,
          zIndex: 2,
          backgroundColor: 'background.paper',
        }}
      >
        <Divider sx={{ opacity: 0.5 }} />
        <Toolbar sx={{ display: 'flex', justifyContent: ['center', 'start'] }}>
          <Pagination
            count={totalCount ? Math.round(totalCount / GRID_PAGE_SIZE) : 0}
            color="primary"
            shape="rounded"
            siblingCount={isMobile ? 0 : 1}
            defaultPage={currentPage}
            sx={{ justifyContent: 'center' }}
            onChange={(e, page) => {
              searchParams.set(
                'offset',
                ((page - 1) * GRID_PAGE_SIZE).toString(),
              );
              setSearchParams(searchParams);
              window.scrollTo(0, 0);
            }}
            renderItem={item => (
              <PaginationItem
                slots={{
                  next: ArrowForward,
                  previous: ArrowBack,
                }}
                {...item}
              />
            )}
          />
        </Toolbar>
      </Box>
    </Box>
  );
};

export const Listings = () => {
  const [searchParams] = useSearchParams();
  const { t } = useLocale();
  const navigate = useNavigate();
  const [selectedListings, setSelectedListings] =
    useState<GridRowSelectionModel>([]);
  const { me } = useAppData();

  const editMode = searchParams.get('edit') === 'true';
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm'),
  );

  const where: Lots_Bool_Exp = JSON.parse(searchParams.get('where') || '{}');
  const order_by = JSON.parse(
    searchParams.get('order_by') || '[{ "created_at": "desc" }]',
  );
  const defaultLimit =
    searchParams.get('view') === 'list' ? LIST_PAGE_SIZE : GRID_PAGE_SIZE;
  const limit = parseInt(searchParams.get('limit') ?? defaultLimit.toString());
  const offset = parseInt(searchParams.get('offset') ?? '0');
  const viewType = searchParams.get('view') ?? 'grid';

  const legacyMatch = useMatch('/listings/*');

  const listingLink = (id: string) =>
    legacyMatch
      ? {
          pathname: `/listings/${toGlobalId('Lot', id)}`,
          search: searchParams.toString(),
        }
      : {
          pathname: `/v2/listings/${id}`,
          search: searchParams.toString(),
        };

  const { data, loading, error, refetch } = useQuery<GetLotsListQuery>(
    GET_LOTS_LIST,
    {
      variables: {
        where,
        order_by,
        limit,
        offset,
      },
    },
  );

  const { data: countData } = useQuery<GetLotsListCountQuery>(
    GET_LOTS_LIST_COUNT,
    {
      variables: {
        where,
      },
    },
  );

  const [deleteLots, deleteOptions] = useMutation<DeleteLotsMutation>(
    DELETE_LOTS,
    {
      onCompleted: () => {
        refetch();
      },
    },
  );

  const handleDelete = (ids: string[]) => {
    deleteLots({
      variables: {
        ids,
      },
    });
  };

  const rows: GridRowsProp<Lot> = data?.lots ?? [];

  const [toggleError, setToggleError] = useState<null | ApolloError>(null);
  const [togglePortalListing] = useMutation<TogglePortalListingMutation>(
    TOGGLE_PORTAL_LISTING,
    {
      onError: e => setToggleError(e),
      update: (cache, { data }) => {
        if (!data?.toggle_portal_listing) {
          return;
        }
        // Because the action doesn't return portal_listing type but PortalListing type
        cache.modify({
          id: `portal_listings:${data.toggle_portal_listing.id}`,
          fields: {
            live() {
              return data.toggle_portal_listing?.live ?? false;
            },
          },
        });
      },
    },
  );

  const columns: GridColDef<Lot>[] = [
    {
      field:
        'property.postcode,property.locality,property.route,property.street_number',
      headerName: t('Listing'),
      width: 250,
      display: 'flex',
      renderCell: ({ row }) => <ListingCell listing={row} />,
    },
    {
      field: 'sellers',
      headerName: t('Seller'),
      sortable: false,
      width: 200,
      renderCell: ({ row }) =>
        row.sellers.length > 0 && <UserInfo user={row.sellers[0].seller} />,
    },
    {
      field: 'broker.last_name,broker.first_name',
      headerName: 'Agent',
      width: 200,
      renderCell: ({ row }) => row.broker && <UserInfo user={row.broker} />,
    },
    {
      field: 'status',
      headerName: t('Status'),
      width: 100,
      display: 'flex',
      renderCell: ({ row }) => (
        <Tag
          status={
            row.status === 'won'
              ? 'success'
              : row.status.startsWith('available')
              ? 'info'
              : ['paused', 'reserved'].includes(row.status)
              ? 'warning'
              : row.status === 'abandoned' || row.status.startsWith('lost')
              ? 'error'
              : 'neutral'
          }
        >
          {row.lots_status.label ? row.lots_status.label : row.status}
        </Tag>
      ),
    },
    {
      field: 'signed_at,created_at',
      headerName: t('Signed'),
      width: 100,
      display: 'flex',
      renderCell: ({ row }) => (
        <Box>
          <Typography
            variant="body2"
            color={row.is_exclusive ? 'success.main' : 'grey.600'}
            fontWeight={row.is_exclusive ? 'bold' : 'normal'}
          >
            {row.is_exclusive
              ? t('exclusive')
              : row.is_exclusive === false
              ? t('non-exclusive')
              : t('unspecified')}
          </Typography>
          <Typography variant="caption" sx={{ display: 'block' }}>
            <TimeAgo dateString={row.signed_at ?? row.created_at} />
          </Typography>
        </Box>
      ),
    },
    {
      field: 'sale_price',
      headerName: t('Price'),
      width: 100,
      align: 'right',
      display: 'flex',
      renderHeader: () => (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
          }}
        >
          <Typography lineHeight="20px" variant="body2" fontWeight={500}>
            {t('Price')}
          </Typography>
          <Typography lineHeight="20px" variant="caption">
            {t('Appraisal')}
          </Typography>
        </Box>
      ),
      renderCell: ({ row }) => <ListingPriceCell row={row} />,
    },
    {
      field: 'enquiries_aggregate.max.created_at',
      headerName: t('Enquiries'),
      width: 100,
      display: 'flex',
      renderHeader: () => (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
          }}
        >
          <Typography lineHeight="20px" variant="body2" fontWeight={500}>
            {t('Enquiries')}
          </Typography>
          <Typography lineHeight="20px" variant="caption">
            {t('Last enquiry')}
          </Typography>
        </Box>
      ),
      renderCell: ({ row }) => (
        <Box>
          <Typography variant="body2" display="inline-block">
            {row.enquiries_aggregate.aggregate?.count ?? 0}
          </Typography>
          &nbsp;
          <Typography variant="caption">{t('enquiries')}</Typography>
          <Typography variant="caption" display="block">
            <TimeAgo
              dateString={row.enquiries_aggregate.aggregate?.max?.created_at}
            />
          </Typography>
        </Box>
      ),
    },
  ];

  // Create an array of my teams ids to sort the portals
  const myTeams = data?.me?.teams_users.map(({ team_id }) => team_id);
  if (data?.portals.length) {
    const portalColumns = [...data.portals]
      // Sort alphabetically first
      .sort((a, b) => (a.name && b.name ? a.name.localeCompare(b.name) : 0))
      // Then sort my team's portal first and other portals last (only visible to admin users)
      .sort((a, b) => {
        const portalATeams = [
          a.team_id,
          ...a.teams_portals.map(({ team_id }) => team_id),
        ];
        const portalBTeams = [
          b.team_id,
          ...b.teams_portals.map(({ team_id }) => team_id),
        ];
        const aIsMine = myTeams?.some(id => portalATeams.includes(id));
        const bIsMine = myTeams?.some(id => portalBTeams.includes(id));
        return aIsMine && !bIsMine ? -1 : !aIsMine && bIsMine ? 1 : 0;
      })
      .map(
        (portal): GridColDef<Lot> => ({
          field: `portals.${portal.id}`,
          headerAlign: 'center',
          renderHeader: () => (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Typography lineHeight="20px" variant="body2" fontWeight={500}>
                {portal.name ?? 'Portal'}
              </Typography>
              <Typography lineHeight="20px" variant="caption">
                {portal.team?.name ?? 'shared'} (
                {portal.portal_listings_aggregate.aggregate?.count ?? 0}/
                {portal.quota ?? '∞'})
              </Typography>
            </Box>
          ),
          width: 100,
          sortable: false,
          align: 'center',
          display: 'flex',
          renderCell: ({ row }) => {
            const portalListing = row.portal_listings.find(
              ({ portal_id }: { portal_id: string }) => portal_id === portal.id,
            );
            const allowedTeamsIds = [
              portal.team_id,
              ...portal.teams_portals.map(({ team_id }) => team_id),
            ];
            const brokerTeamIds = row.broker?.teams_users.map(
              ({ team_id }) => team_id,
            );
            const disabled = !brokerTeamIds?.some(id =>
              allowedTeamsIds.includes(id),
            );
            const enquiriesCount = row.enquiries.filter(
              ({ portal_id }) => portal_id === portal.id,
            ).length;
            return (
              <div
                style={{
                  position: 'relative',
                  display: 'flex',
                  marginTop: -10,
                }}
              >
                <LoadingCheckBox
                  disabled={disabled}
                  checked={portalListing?.live ?? false}
                  onClick={async e => {
                    e.stopPropagation();
                    return togglePortalListing({
                      variables: {
                        portal_id: portal.id,
                        lot_id: row.id,
                      },
                    }).then(
                      ({ data }) => data?.toggle_portal_listing?.live ?? false,
                    );
                  }}
                />
                <Typography
                  variant="caption"
                  style={{
                    position: 'absolute',
                    bottom: -10,
                    width: '100%',
                    textAlign: 'center',
                  }}
                >
                  {enquiriesCount}
                </Typography>
              </div>
            );
          },
        }),
      );
    columns.push(...portalColumns);
  }

  return (
    <>
      <TopbarTitle>{t('Listings')}</TopbarTitle>
      {/* Sticky header */}
      <NavigationPortal position={['sticky']} />
      <Box
        sx={{
          position: 'sticky',
          top: 64,
          zIndex: 4,
          backgroundColor: 'background.paper',
        }}
      >
        <ListToolbar
          tableName="lots"
          newLink="new"
          searchComponent={
            <LotSelect
              size="small"
              InputProps={{
                startAdornment: <Search />,
              }}
              sx={{ maxWidth: 350, minWidth: 100, flexGrow: 1, ml: 1 }}
              autoFocus
              onChange={lotId => {
                if (!lotId) {
                  return;
                }
                navigate(listingLink(lotId));
              }}
            />
          }
          quickFilters={quickFilters(t)}
          tableFiltersTables={tableFilters}
          viewLinks={[
            {
              name: 'grid',
              icon: <GridViewRounded />,
            },
            {
              name: 'list',
              icon: <TableRows />,
            },
          ]}
          ExportDialog={ListingsExport}
          ImportDialog={ListingsImport}
          bulkActions={
            <>
              {me?.is_admin && (
                <LoadingButton
                  disabled={selectedListings.length === 0}
                  disableElevation
                  onClick={() => {
                    handleDelete(selectedListings as string[]);
                  }}
                  loading={deleteOptions.loading}
                  loadingPosition="start"
                  startIcon={<Delete />}
                  color="error"
                >
                  {t('Delete')}
                </LoadingButton>
              )}
            </>
          }
        />
        <Divider sx={{ opacity: 0.5 }} />
      </Box>
      {toggleError && (
        <MutationErrorModal
          error={toggleError}
          onClose={() => setToggleError(null)}
        />
      )}
      {error ? (
        <Alert severity="error" sx={{ m: 2 }}>
          <pre>{JSON.stringify(error, null, 2)}</pre>
        </Alert>
      ) : viewType === 'list' ? (
        <RaDataGrid
          columns={columns}
          rows={rows}
          rowCount={countData?.lots_aggregate.aggregate?.count ?? 0}
          onRowClick={({ id }, e) => {
            if (e.metaKey || e.ctrlKey) {
              window.open(listingLink(id as string).pathname, '_blank');
              return;
            }
            navigate(listingLink(id as string));
          }}
          loading={loading}
          rowHeight={60}
          checkboxSelection={editMode}
          disableRowSelectionOnClick
          onRowSelectionModelChange={newSelection => {
            setSelectedListings(newSelection);
          }}
          columnVisibilityModel={{
            [GRID_CHECKBOX_SELECTION_COL_DEF.field]: editMode,
          }}
          rowSelectionModel={selectedListings}
          pinnedColumns={{
            left: isMobile
              ? undefined
              : [
                  GRID_CHECKBOX_SELECTION_COL_DEF.field,
                  'property.postcode,property.locality,property.route,property.street_number',
                ],
          }}
          sx={{
            borderRadius: 0,
            borderColor: 'transparent',
          }}
        />
      ) : (
        <GridView
          lots={data?.lots}
          listingLink={listingLink}
          totalCount={countData?.lots_aggregate.aggregate?.count}
          loading={loading}
        />
      )}
      <Suspense fallback={null}>
        <Routes>
          <Route
            path="new/*"
            element={
              legacyMatch ? <LotCreateDrawer open /> : <CreateListingDrawer />
            }
          />
          <Route
            path=":lotId/*"
            element={legacyMatch ? <LegacyListingDrawer /> : <ListingDrawer />}
          />
        </Routes>
      </Suspense>
    </>
  );
};

export default Listings;
