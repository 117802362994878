import { gql, useMutation, useQuery } from '@apollo/client';
import { Box, LinearProgress, Paper, Typography } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';

import { useLocale } from '../../../src/hooks/locale';
import {
  type GetAvailablePortalsQuery,
  Lots_Status_Enum_Enum,
} from '../../__generated__/graphql';
import {
  GET_LISTING_DETAILS,
  UPDATE_LISTING,
} from '../../pages/listings/lotsQueries';
import { useAppData } from '../../providers/AppDataProvider';
import { ListingPortalsList } from '../listing-overview/ListingPortalsButton';

import { type ListingCreationStepComponent } from './CreateListingStepper';

const GET_AVAILABLE_PORTALS = gql`
  query GetAvailablePortals($team_ids: [uuid!], $lot_id: uuid!) {
    portals(
      order_by: { name: asc }
      where: {
        _or: [
          { portal_listings: { lot_id: { _eq: $lot_id }, live: { _eq: true } } }
          { _and: [{ team_id: { _in: $team_ids } }, { active: { _eq: true } }] }
          {
            _and: [
              { teams_portals: { team_id: { _in: $team_ids } } }
              { active: { _eq: true } }
            ]
          }
        ]
      }
    ) {
      id
      name
      active
    }
  }
`;

export const ListingPublicationStep: ListingCreationStepComponent = ({
  footerActionsComponent,
  onFormSubmitted,
  listing,
}) => {
  const { t } = useLocale();
  const { me } = useAppData();
  const { data: portalsData } = useQuery<GetAvailablePortalsQuery>(
    GET_AVAILABLE_PORTALS,
    {
      variables: {
        team_ids: me?.teams.map(({ team_id }) => team_id),
        lot_id: listing?.id,
      },
      skip: listing == null,
    },
  );
  const [updateListing] = useMutation(UPDATE_LISTING);
  const formMethods = useForm();

  if (portalsData == null || listing == null) {
    return <LinearProgress />;
  }

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Box
        sx={{
          flexGrow: 1,
          overflow: 'auto',
          px: 2,
          py: 2,
        }}
      >
        <Typography variant="h6" mb={2}>
          {t('Available portals')}
        </Typography>

        <Paper sx={{ px: 2 }}>
          <ListingPortalsList
            listing={listing}
            fullWidth
            allPortals={portalsData.portals}
            onPortalListingChange={() => {
              // Will update the status of the listing on the first publication.
              if (listing.status === Lots_Status_Enum_Enum.Draft) {
                updateListing({
                  variables: {
                    id: listing.id,
                    lot: {
                      status: Lots_Status_Enum_Enum.Available,
                    },
                  },
                });
              }
            }}
            refetchQueries={[
              {
                // TODO: Maybe use a simpler query in this component retrieving PortalListingButton fragment instead.
                query: GET_LISTING_DETAILS,
                variables: { id: listing.id },
              },
            ]}
          />
        </Paper>
      </Box>
      <FormProvider {...formMethods}>
        {/* Empty form to match the footer behavior */}
        <form
          onSubmit={() => onFormSubmitted({ action: 'update' }, true, true)}
        >
          {footerActionsComponent}
        </form>
      </FormProvider>
    </Box>
  );
};
