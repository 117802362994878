import * as React from 'react';

import { useConstant } from '@realadvisor/hooks';
import { TranslatorDevtool } from '@realadvisor/translator/devtool';
import {
  type Translate,
  type TranslatorResource,
  addTranslatorResources,
  clearTranslatorResources,
  getTranslatorResources,
  makeTranslatorStore,
  translate,
} from '@realadvisor/translator/engine';
import de from 'date-fns/locale/de';
import en from 'date-fns/locale/en-GB';
import es from 'date-fns/locale/es';
import fr from 'date-fns/locale/fr';
import it from 'date-fns/locale/it';

import {
  api_origin,
  isProduction,
  webOrigin,
  webOriginDe,
  webOriginEs,
  webOriginFr,
  webOriginGb,
  webOriginIt,
} from './config';

export type Language = 'en' | 'fr' | 'de' | 'it' | 'es';

export type CountryCode = 'CH' | 'FR' | 'DE' | 'IT' | 'ES' | 'GB';

export const Currencies = ['CHF', 'GBP', 'EUR'] as const;

export type LotCurrency = (typeof Currencies)[number];
export type LatLng = {
  lat: number;
  lng: number;
};

export const fallbackLanguage = 'en';

export const languagesList: Language[] = ['en', 'fr', 'de', 'it', 'es'];

const dateLocales = {
  en,
  fr,
  de,
  it,
  es,
};

export const getLanguage = (string: string | null | void): Language | null => {
  switch (string) {
    case 'en':
      return 'en';
    case 'fr':
      return 'fr';
    case 'de':
      return 'de';
    case 'it':
      return 'it';
    case 'es':
      return 'es';
    default:
      return null;
  }
};

export const getLandingPageLink = (
  countryCode: CountryCode,
  language: Language,
): string => {
  switch (countryCode) {
    case 'FR':
      return language === 'en' ? `${webOriginFr}/en` : `${webOriginFr}/fr`;
    case 'ES':
      return `${webOriginEs}/es`;
    case 'DE':
      return `${webOriginDe}/de`;
    case 'IT':
      return `${webOriginIt}/it`;
    case 'GB':
      return `${webOriginGb}/en`;
    case 'CH':
      return language !== 'es' ? `${webOrigin}/${language}` : `${webOrigin}/en`;
    default:
      return `${webOrigin}/en`;
  }
};

export type IntlLocale =
  | 'en-GB'
  | 'de-CH'
  | 'de-DE'
  | 'it-CH'
  | 'fr-FR'
  | 'fr-CH'
  | 'es-ES'
  | 'it-IT';

export const getLocale = (
  countryCode: string,
  language: Language,
  countryCodeOverride: boolean,
): IntlLocale => {
  switch (language) {
    case 'en':
      return 'en-GB';
    case 'de':
      return countryCodeOverride && countryCode === 'CH' ? 'de-CH' : 'de-DE';
    case 'it':
      return countryCodeOverride && countryCode === 'CH' ? 'it-CH' : 'it-IT';
    case 'es':
      return 'es-ES';
    case 'fr':
      return countryCodeOverride && countryCode === 'CH' ? 'fr-CH' : 'fr-FR';
    default:
      return 'en-GB';
  }
};

export const getDefaultLocationOnMap = (countryCode: string): LatLng => {
  switch (countryCode) {
    case 'ES':
      // MADRID
      return {
        lat: 40.4167,
        lng: -3.703,
      };
    case 'FR':
      // PARIS
      return {
        lat: 48.8566,
        lng: 2.3522,
      };
    case 'DE':
      // BERLIN
      return {
        lat: 52.5163,
        lng: 13.3889,
      };

    case 'GB':
      // LONDON
      return {
        lat: 51.5072,
        lng: 0.1276,
      };

    case 'IT':
      // ROME
      return {
        lat: 41.902782,
        lng: 12.496366,
      };

    case 'CH':
    default:
      // GENEVA
      return {
        lat: 46.2044,
        lng: 6.1432,
      };
  }
};

export const getCurrencyByCountryCode = (
  countryCode: CountryCode | string,
): LotCurrency => {
  switch (countryCode) {
    case 'CH':
      return 'CHF';
    case 'GB':
      return 'GBP';
    default:
      return 'EUR';
  }
};

const fetchLocaleData = (signal: AbortSignal, language: Language) => {
  const uri = `/locales/${language}/common.json`;
  const request = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    signal,
  };
  return fetch(uri, request)
    .then(response => response.json())
    .then(data => [{ language, data }]);
};

const fetchTranslatorData = (signal: AbortSignal, languages: Language[]) => {
  const uri = `${api_origin}/api/translator/list`;
  const request: RequestInit = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    signal,
    body: JSON.stringify({ project: 'crm', languages }),
  };
  return fetch(uri, request).then(response => {
    if (response.ok === false) {
      throw Error(response.statusText);
    }
    return response.json();
  });
};

const updateTranslatorData = (
  signal: AbortSignal,
  diff: ReadonlyArray<TranslatorResource>,
  updatedBy: string,
) => {
  const uri = `${api_origin}/api/translator/update`;
  const request: RequestInit = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    signal,
    body: JSON.stringify({ updatedBy, project: 'crm', diff }),
  };
  return fetch(uri, request).then(response => {
    if (response.ok === false) {
      throw Error(response.statusText);
    }
  });
};

export type Locale = {
  t: Translate;
  // all locales has the same type which can be extracted
  // with typeof from any of them
  dateLocale: typeof en;
  // to create language dependent external links
  language: Language;
  countryCode: string;
  locale: IntlLocale;
  intlLocale: IntlLocale;
};

export const LocaleContext = React.createContext<Locale>({
  t: text => text,
  dateLocale: en,
  language: 'en',
  countryCode: 'CH',
  locale: 'en-GB',
  intlLocale: 'en-GB',
});

const trimLeadingSlash = (pathname: string) =>
  pathname.startsWith('/') ? pathname.slice(1) : pathname;

const parseLeadingSegment = (pathname: string, available: string[]) => {
  const [leadingSegment] = trimLeadingSlash(pathname).split('/');
  if (available.includes(leadingSegment)) {
    return leadingSegment;
  } else {
    return null;
  }
};

const sliceLeadingSegment = (pathname: string, available: string[]) => {
  if (pathname === '' || pathname === '/') {
    return '';
  }
  const leadingSegment = parseLeadingSegment(pathname, available);
  if (leadingSegment == null) {
    return trimLeadingSlash(pathname);
  } else {
    return trimLeadingSlash(
      trimLeadingSlash(pathname).slice(leadingSegment.length),
    );
  }
};

type UrlMaker = (oldUrl: string) => string;

export const createUrlMaker =
  (language: string): UrlMaker =>
  oldUrl => {
    const base = sliceLeadingSegment(oldUrl, languagesList);
    return `/${language}/${base}`;
  };

export const extractLanguageFromUrl = () => {
  const possibleLanguage = location.pathname.split('/')[1];
  return getLanguage(possibleLanguage);
};

export const replacePathnameLanguage = (
  pathname: string,
  language: Language,
) => {
  const possibleLanguage = pathname.split('/')[1];
  const parsedLanguage = getLanguage(possibleLanguage);
  const newPathname =
    parsedLanguage == null
      ? pathname
      : // slice leading slash and language
        `/${pathname.split('/').slice(2).join('/')}`;
  return `/${language}${newPathname}`;
};

export const LocaleProvider = ({
  currentLanguage,
  countryCode,
  children,
}: {
  currentLanguage: Language;
  countryCode: string;
  children: React.ReactNode;
}) => {
  const devtool = useConstant(
    () => new URLSearchParams(location.search).get('locize') === 'true',
  );

  const [store, setStore] = React.useState(() =>
    makeTranslatorStore({
      currentLanguage,
      fallbackLanguage,
      devtool: devtool && isProduction === false,
      resources: [],
      locale: getLocale(countryCode, currentLanguage, false),
    }),
  );

  React.useEffect(() => {
    const controller = new AbortController();
    let promise;
    if (store.devtool) {
      promise = fetchTranslatorData(controller.signal, languagesList);
    } else {
      promise = fetchLocaleData(controller.signal, currentLanguage);
    }
    promise
      .then(newValue => {
        addTranslatorResources(store.index, newValue);
        // trigger local update
        setStore(store => ({ ...store }));
      })
      .catch(error => {
        if (error.name !== 'AbortError') {
          throw error;
        }
      });
    return () => controller.abort();
  }, [store.devtool, store.index, currentLanguage]);

  // set correct value to the lang attribute of <html> tag
  React.useEffect(() => {
    if (document.documentElement) {
      document.documentElement.setAttribute('lang', currentLanguage);
    }
  }, [currentLanguage]);

  const locale = React.useMemo<Locale>(
    () => ({
      t: (key, options) => translate(store, key, options),
      dateLocale: dateLocales[currentLanguage],
      language: getLanguage(currentLanguage) ?? 'en',
      countryCode,
      locale: getLocale(countryCode, currentLanguage, true),
      intlLocale: getLocale(countryCode, currentLanguage, false),
    }),
    [currentLanguage, countryCode, store],
  );

  return (
    <>
      {store.devtool && (
        <TranslatorDevtool
          project="crm"
          store={store}
          onStoreInvalidate={() => {
            // trigger local update
            setStore(store => ({ ...store }));
          }}
          onPublish={updatedBy => {
            const controller = new AbortController();
            const newDiff = getTranslatorResources(store.draft);
            updateTranslatorData(controller.signal, newDiff, updatedBy)
              .then(() => {
                addTranslatorResources(store.index, newDiff);
                clearTranslatorResources(store.draft);
                // trigger local update
                setStore(store => ({ ...store }));
              })
              .catch(error => {
                if (error.name !== 'AbortError') {
                  throw error;
                }
              });
          }}
        />
      )}
      <LocaleContext.Provider value={locale}>{children}</LocaleContext.Provider>
    </>
  );
};
