import { useEffect, useMemo, useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton, TabContext, TabList, TabPanel } from '@mui/lab';
import {
  Alert,
  Box,
  Button,
  FormLabel,
  Grid,
  LinearProgress,
  Paper,
  Snackbar,
  Tab,
  Typography,
} from '@mui/material';
import { type GridColDef } from '@mui/x-data-grid-pro';
import { type Control, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useLocale } from '../../../src/hooks/locale';
import { gql } from '../../__generated__';
import {
  type GetPropertyImagesQuery,
  type Property_Images_Set_Input,
} from '../../__generated__/graphql';
import { RaCheckboxGroup } from '../../components/form/RaCheckboxGroup';
import { RaRadioGroup } from '../../components/form/RaRadioGroup';
import { RaTextField } from '../../components/form/RaTextfield';
import {
  BrochureImagePreview,
  type PropertyImageBackgroundColor,
  type PropertyImageCrop,
  type PropertyImageSize,
} from '../../components/image-gallery/BrochureImagePreview';
import {
  ImageGallery,
  type ImageGalleryItem,
  useCurrentEditImage,
} from '../../components/image-gallery/NewImageGallery';
import { useWatchMultiple } from '../../utils/reactHookForm';

export const GET_PROPERTY_IMAGES = gql(`
  query GetPropertyImages($property_id: uuid!) {
    property_images(
      where: { property_id: { _eq: $property_id } }
      order_by: { order_nr: asc }
    ) {
      id
      property_id
      title
      description
      display_on_brochure
      display_on_listing
      is_floor_plan
      size
      crop
      background_color
      landscape_size
      landscape_crop
      landscape_background_color
      order_nr
      image {
        id
        url
      }
    }
  }
`);

export const INSERT_PROPERTY_IMAGE = gql(`
  mutation InsertPropertyImage($object: property_images_insert_input!) {
    insert_property_images_one(object: $object) {
      id
      order_nr
      display_on_brochure
      display_on_listing
      image {
        id
        url
      }
    }
  }
`);

export const INSERT_PROPERTY_IMAGES_BATCH = gql(`
  mutation InsertPropertyImagesBatch(
    $objects: [property_images_insert_input!]!
  ) {
    insert_property_images(objects: $objects) {
      returning {
        id
        order_nr
        image {
          id
          url
        }
      }
    }
  }
`);

export const DELETE_PROPERTY_IMAGE = gql(`
  mutation deletePropertyImage($id: uuid!) {
    delete_property_images_by_pk(id: $id) {
      id
    }
  }
`);

export const REORDER_PROPERTY_IMAGES = gql(`
  mutation UpdatePropertyImagesOrder($id: uuid!, $target_position: Int!) {
    update_property_images_by_pk(
      pk_columns: { id: $id }
      _set: { order_nr: $target_position }
    ) {
      id
      order_nr
    }
  }
`);

export const UPDATE_PROPERTY_IMAGE = gql(`
  mutation UpdatePropertyImage(
    $id: uuid!
    $object: property_images_set_input!
  ) {
    update_property_images_by_pk(pk_columns: { id: $id }, _set: $object) {
      id
    }
  }
`);

interface ListingPropertyImagesProps {
  propertyId?: string | null;
  canEditImagesMeta?: boolean;
}

export type PropertyImageForm = Pick<
  Property_Images_Set_Input,
  | 'description'
  | 'display_on_brochure'
  | 'display_on_listing'
  | 'is_floor_plan'
  | 'title'
> & {
  landscape_background_color?: PropertyImageBackgroundColor | null;
  background_color?: PropertyImageBackgroundColor | null;
  landscape_crop?: PropertyImageCrop | null;
  crop?: PropertyImageCrop | null;
  landscape_size?: PropertyImageSize | null;
  size?: PropertyImageSize | null;
};

const keyNotInArray = <T extends string, V extends T>(
  item: T,
  arr: V[],
): item is Exclude<T, V> => !arr.includes(item as any);

const removeExtraFields = <
  T extends Record<string, any>,
  K extends Extract<keyof T, string>,
>(
  obj: T,
  ...keysToRemove: K[]
): Omit<T, K> => {
  const newObj = {} as Omit<T, K>;

  for (const key in obj) {
    if (keyNotInArray(key, keysToRemove)) {
      newObj[key] = obj[key];
    }
  }

  return newObj;
};

const BrochureLayoutOptions: React.FC<{
  fieldPrefix?: 'landscape_';
  control: Control<PropertyImageForm, any>;
  imageUrl: string;
}> = ({ fieldPrefix, control, imageUrl }) => {
  const { t } = useLocale();
  const {
    crop,
    size,
    background_color: bg,
    landscape_crop: lsCrop,
    landscape_size: lsSize,
    landscape_background_color: lsBg,
  } = useWatchMultiple<
    PropertyImageForm,
    | 'crop'
    | 'size'
    | 'background_color'
    | 'landscape_crop'
    | 'landscape_size'
    | 'landscape_background_color'
  >({
    control,
    name: [
      `${fieldPrefix ?? ''}crop`,
      `${fieldPrefix ?? ''}size`,
      `${fieldPrefix ?? ''}background_color`,
    ],
  });

  const watchCrop = fieldPrefix === 'landscape_' ? lsCrop : crop;

  return (
    <Grid container>
      <Grid item xs={12} sm={8}>
        <RaRadioGroup
          control={control}
          label={t('Size')}
          name={`${fieldPrefix ?? ''}size`}
          radioProps={{ size: 'small' }}
          sx={{ mb: 1 }}
          radios={[
            {
              label: t('Full page'),
              value: 'full_page',
            },
            {
              label: t('Half page'),
              value: 'half_page',
            },
            {
              label: t('Quarter page'),
              value: 'quarter_page',
            },
          ]}
        />
        <RaRadioGroup
          control={control}
          label={t('Crop')}
          name={`${fieldPrefix ?? ''}crop`}
          radioProps={{ size: 'small' }}
          sx={{ mb: 1 }}
          radios={[
            {
              label: t('Fit'),
              value: 'fit',
            },
            {
              label: t('Fill'),
              value: 'fill',
            },
          ]}
        />
        {watchCrop === 'fit' && (
          <RaRadioGroup
            control={control}
            label={t('Background color')}
            name={`${fieldPrefix ?? ''}background_color`}
            radioProps={{ size: 'small' }}
            radios={[
              {
                label: t('White'),
                value: 'white',
              },
              {
                label: t('Black'),
                value: 'black',
              },
            ]}
          />
        )}
      </Grid>
      <Grid item xs={12} sm={4}>
        <FormLabel sx={{ fontWeight: 500, mb: 1, display: 'block' }}>
          {t('Preview')}
        </FormLabel>
        <BrochureImagePreview
          bg={fieldPrefix === 'landscape_' ? lsBg : bg}
          crop={watchCrop}
          size={fieldPrefix === 'landscape_' ? lsSize : size}
          orientation={fieldPrefix === 'landscape_' ? 'landscape' : 'portrait'}
          imageUrl={imageUrl}
        />
      </Grid>
    </Grid>
  );
};

const PropertyImageEditForm: React.FC<{
  images: GetPropertyImagesQuery['property_images'];
  onSubmit: (imageId: string, data: PropertyImageForm) => Promise<any>;
  onCancel: (isDirty: boolean) => void;
}> = ({ images, onSubmit, onCancel }) => {
  const currentEditedImage = useCurrentEditImage();
  const image = images.find(img => img.id === currentEditedImage?.id);
  const { t } = useLocale();
  const [selectedTab, setSelectedTab] = useState<'portrait' | 'landscape'>(
    'portrait',
  );

  const defaultValues = useMemo(
    () =>
      image != null
        ? removeExtraFields(image, 'id', 'image', 'order_nr', 'property_id')
        : {},
    [image],
  );

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isDirty, isSubmitting },
    watch,
    reset,
  } = useForm<PropertyImageForm>({
    defaultValues,
  });

  useEffect(() => {
    if (defaultValues) {
      reset(defaultValues);
    }
  }, [defaultValues, reset]);

  if (image == null) {
    return null;
  }

  return (
    <form onSubmit={handleSubmit(data => onSubmit(image.id, data))}>
      <Grid container spacing={2} padding={2} pt={0} alignItems="end">
        <Grid item xs={12}>
          <RaCheckboxGroup
            control={control}
            label={t('Display options')}
            checkboxes={[
              {
                name: 'display_on_brochure',
                label: t('Display on brochure'),
                type: 'checkbox',
              },
              {
                name: 'display_on_listing',
                label: t('Display on listing'),
                type: 'checkbox',
              },
              {
                name: 'is_floor_plan',
                label: t('Is floor plan'),
                type: 'checkbox',
              },
            ]}
            formValues={watch()}
          />
        </Grid>
        <Grid item xs={12}>
          <RaTextField
            name="title"
            label={t('Title')}
            errors={errors}
            register={register}
          />
        </Grid>
        <Grid item xs={12}>
          <RaTextField
            name="description"
            label={t('Description')}
            errors={errors}
            register={register}
            multiline
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" sx={{ fontWeight: 500, mb: 1 }}>
            {t('Brochure layout options')}
          </Typography>

          <TabContext value={selectedTab}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList
                onChange={(_, newValue) => setSelectedTab(newValue)}
                variant="fullWidth"
                sx={{ minHeight: 0 }}
              >
                <Tab
                  sx={{ minHeight: 0 }}
                  label={t('Portrait')}
                  value="portrait"
                />
                <Tab
                  sx={{ minHeight: 0 }}
                  label={t('Landscape')}
                  value="landscape"
                />
              </TabList>
            </Box>
            <TabPanel value="portrait" sx={{ p: 0, pt: 2 }}>
              <BrochureLayoutOptions
                control={control}
                imageUrl={image.image.url ?? ''}
              />
            </TabPanel>
            <TabPanel value="landscape" sx={{ p: 0, pt: 2 }}>
              <BrochureLayoutOptions
                control={control}
                fieldPrefix="landscape_"
                imageUrl={image.image.url ?? ''}
              />
            </TabPanel>
          </TabContext>
        </Grid>
      </Grid>
      <Box
        display="flex"
        justifyContent="flex-end"
        gap={1}
        padding={1}
        sx={{ position: 'sticky', bottom: 0 }}
        component={Paper}
        elevation={3}
        borderRadius={0}
      >
        <Button
          variant="outlined"
          color="neutral"
          disabled={isSubmitting}
          onClick={() => {
            reset(defaultValues);
            onCancel(isDirty);
          }}
        >
          {t('Cancel')}
        </Button>
        <LoadingButton
          type="submit"
          variant="contained"
          color="primary"
          disabled={!isDirty}
          loading={isSubmitting}
          loadingPosition="start"
          startIcon={<SaveIcon />}
        >
          {t('Save')}
        </LoadingButton>
      </Box>
    </form>
  );
};

export const ListingPropertyImages: React.FC<ListingPropertyImagesProps> = ({
  propertyId,
  canEditImagesMeta = true,
}) => {
  const { t } = useLocale();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [snackbarMessage, setSnackbarMessage] = useState<{
    message: string;
    severity: 'success' | 'error';
  } | null>(null);
  const { data, loading } = useQuery<GetPropertyImagesQuery>(
    GET_PROPERTY_IMAGES,
    {
      variables: { property_id: propertyId },
      returnPartialData: true,
      skip: propertyId == null,
    },
  );

  const [deleteImage] = useMutation(DELETE_PROPERTY_IMAGE, {
    onError: () => {
      setSnackbarMessage({
        severity: 'error',
        message: t('An error occured while deleting the image.'),
      });
    },
    update: (cache, _res, { variables }) => {
      if (!variables) {
        return;
      }

      const { id } = variables;
      cache.evict({ id: `property_images:${id}` });
    },
  });

  const [insertImagesBatch] = useMutation(INSERT_PROPERTY_IMAGES_BATCH, {
    onError: () => {
      setSnackbarMessage({
        severity: 'error',
        message: t('The images could not be uploaded.'),
      });
    },
    refetchQueries: [
      { query: GET_PROPERTY_IMAGES, variables: { property_id: propertyId } },
    ],
  });

  const [reorderImage] = useMutation(REORDER_PROPERTY_IMAGES, {
    onError: () => {
      setSnackbarMessage({
        severity: 'error',
        message: t('An error occured while reordering the images.'),
      });
    },
    refetchQueries: [
      { query: GET_PROPERTY_IMAGES, variables: { property_id: propertyId } },
    ],
  });
  const [updateImage] = useMutation(UPDATE_PROPERTY_IMAGE, {
    onError: () => {
      setSnackbarMessage({
        severity: 'error',
        message: t('An error occured while updating the image.'),
      });
    },
    refetchQueries: [
      { query: GET_PROPERTY_IMAGES, variables: { property_id: propertyId } },
    ],
  });

  const propertyImages = data?.property_images.map(propertyImg => {
    const { id, title, description, order_nr, image, ...rest } = propertyImg;

    return {
      id,
      title: title ?? '',
      description: description ?? '',
      order: order_nr,
      image: {
        id: image.id,
        url: image.url,
      },
      ...rest,
    };
  });

  const extraProps: {
    extraMetaColumns?: GridColDef<ImageGalleryItem>[];
    editForm?: React.ReactNode;
    hideEditColumn?: boolean;
  } = canEditImagesMeta
    ? {
        extraMetaColumns: [
          {
            field: 'display_on_listing',
            headerName: t('On listing'),
            width: 100,
            type: 'boolean',
          },
          {
            field: 'display_on_brochure',
            headerName: t('In brochure'),
            width: 100,
            type: 'boolean',
          },
        ],
        editForm: (
          <PropertyImageEditForm
            images={data?.property_images ?? []}
            onSubmit={(imageId, data) =>
              updateImage({ variables: { id: imageId, object: data } })
            }
            onCancel={isDirty => {
              if (isDirty) {
                if (
                  window.confirm(
                    t(
                      'You have unsaved changes. Are you sure you want to close?',
                    ),
                  ) === false
                ) {
                  return;
                }
              }

              navigate({
                pathname: './',
                search: searchParams.toString(),
              });
            }}
          />
        ),
      }
    : { hideEditColumn: true };

  return (
    <>
      {loading && (
        <LinearProgress
          sx={{
            position: 'absolute',
            top: 0,
            width: '100%',
            zIndex: 2000,
          }}
        />
      )}
      {propertyImages && (
        <Box sx={{ flex: 1, minHeight: 0 }}>
          <ImageGallery
            images={propertyImages}
            onFilesUploaded={files =>
              insertImagesBatch({
                variables: {
                  objects: files.map(file => ({
                    property_id: propertyId,
                    image: { data: file },
                  })),
                },
              })
            }
            onDelete={id => deleteImage({ variables: { id } })}
            onImageOrderChange={(id, targetPosition) =>
              reorderImage({
                variables: {
                  id,
                  target_position: targetPosition,
                },
              })
            }
            {...extraProps}
          />
          <Snackbar
            open={snackbarMessage != null}
            autoHideDuration={6000}
            onClose={() => setSnackbarMessage(null)}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          >
            <Alert
              onClose={() => setSnackbarMessage(null)}
              severity={snackbarMessage?.severity}
              variant="filled"
              sx={{ width: '100%', alignItems: 'center' }}
            >
              {snackbarMessage?.message ?? ''}
            </Alert>
          </Snackbar>
        </Box>
      )}
    </>
  );
};
