import { forwardRef } from 'react';

import {
  FormControl,
  FormHelperText,
  FormLabel,
  InputAdornment,
  type InputBaseProps,
  OutlinedInput,
} from '@mui/material';
import { type Control, Controller } from 'react-hook-form';
import { IMaskInput } from 'react-imask';

import { useLocale } from '../../../src/hooks/locale';
import { getDecimalSymbol } from '../../../src/utils/number-format';

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  min?: number;
  max?: number;
  decimalnumbers?: number;
  disableformatting?: boolean;
}

const TextMaskCustom = forwardRef<HTMLInputElement, CustomProps>(
  (props, ref) => {
    const { intlLocale } = useLocale();
    const { onChange, min, max, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask={Number}
        scale={props.decimalnumbers ?? 0}
        thousandsSeparator={props.disableformatting === true ? '' : `'`}
        radix={getDecimalSymbol(intlLocale).decimalSymbol}
        mapToRadix={[',', '.']}
        unmask={true}
        min={min}
        max={max}
        inputRef={ref}
        onAccept={(value: string) => {
          onChange({ target: { name: props.name, value } });
        }}
        overwrite
        inputMode="numeric"
      />
    );
  },
);

export type RaNumberProps = Omit<
  InputBaseProps,
  | 'name'
  | 'autoComplete'
  | 'size'
  | 'fullWidth'
  | 'sx'
  | 'minRows'
  | 'value'
  | 'onChange'
> & {
  control: Control<any>;
  name: string;
  errors: any;
  label: string;
  min?: number;
  max?: number;
  helpText?: string;
  decimalNumbers?: number;
  suffix?: string;
  prefix?: string;
  disableFormatting?: boolean;
};

export const RaNumber: React.FC<RaNumberProps> = ({
  control,
  name,
  label,
  required = false,
  multiline = false,
  min,
  max,
  helpText,
  decimalNumbers,
  suffix,
  prefix,
  errors,
  disableFormatting,
  ...inputProps
}) => {
  const { t } = useLocale();

  return (
    <Controller
      name={name}
      control={control}
      rules={{ required: required ? t('This field is required') : undefined }}
      render={({
        field: { onChange, onBlur, name, value, ref },
        fieldState: { error },
      }) => (
        <FormControl fullWidth error={!!error || errors[name]}>
          <FormLabel style={{ marginBottom: '0.5rem', fontWeight: 500 }}>
            {label}
          </FormLabel>
          <OutlinedInput
            autoComplete="off"
            size="small"
            fullWidth
            sx={{
              background: 'white',
              '& .MuiInputAdornment-positionStart': {
                marginTop: '0px !important',
              },
            }}
            multiline={multiline}
            minRows={multiline ? 3 : undefined}
            inputComponent={TextMaskCustom as any}
            inputProps={{
              min,
              max,
              decimalnumbers: decimalNumbers,
              disableformatting: disableFormatting,
            }}
            endAdornment={
              suffix ? (
                <InputAdornment position="end">{suffix}</InputAdornment>
              ) : null
            }
            startAdornment={
              prefix ? (
                <InputAdornment position="start">{prefix}</InputAdornment>
              ) : null
            }
            value={value?.toString() ?? ''}
            name={name}
            onBlur={onBlur}
            onChange={event => {
              let parsedValue = null;
              if (event.target.value != null) {
                const parsed = parseFloat(event.target.value);

                if (!Number.isNaN(parsed)) {
                  parsedValue = parsed;
                }
              }

              if (value !== parsedValue) {
                onChange({
                  target: { name, value: parsedValue },
                });
              }
            }}
            ref={ref}
            {...inputProps}
          />
          <FormHelperText>
            {error?.message ?? errors[name]?.message ?? helpText}
          </FormHelperText>
        </FormControl>
      )}
    />
  );
};
