import { gql } from '../../__generated__';
import type { GetListingDetailsQuery } from '../../__generated__/graphql';

export type GetListingDetailsData = NonNullable<
  GetListingDetailsQuery['lots_by_pk']
>;

export const GET_LOT = gql(`
  query GetLot($id: uuid!) {
    lots_by_pk(id: $id) {
      id
      title
      property {
        id

        # to diplay address as fallback when title is empty
        street_number
        route
        locality
        postcode
      }
    }
  }
`);

export const GET_LOTS_LIST = gql(`
  query GetLotsList(
    $where: lots_bool_exp!
    $order_by: [lots_order_by!]!
    $limit: Int!
    $offset: Int!
  ) {
    me {
      id
      teams_users {
        team_id
      }
    }
    lots(where: $where, order_by: $order_by, limit: $limit, offset: $offset) {
      ...LotCardCarousel_lot
      ...LotCardInfo_lot
      id
      created_at
      updated_at
      signed_at
      title
      offer_type
      sale_price
      rent_net
      rent_extra
      status
      lots_status {
        label
      }
      is_exclusive
      ...ListingCell_listing
      broker {
        id
        teams_users {
          team_id
        }
        ...UserInfo_user
      }
      sellers {
        seller {
          id
          ...UserInfo_user
        }
      }
      portal_listings {
        id
        portal_id
        live
      }
      property {
        id
        route
        street_number
        locality
        postcode
        land_surface
        living_surface
        built_surface
        property_type {
          id
          label
          main_type
        }
        latest_appraisal {
          id
          min
          value
          max
        }
      }
      enquiries {
        portal_id
      }
      enquiries_aggregate {
        aggregate {
          count
          max {
            created_at
          }
        }
      }
    }
    portals(where: { active: { _eq: true } }) {
      id
      team_id
      name
      quota
      portal_listings_aggregate(where: { live: { _eq: true } }) {
        aggregate {
          count
        }
      }
      team {
        id
        name
      }
      teams_portals {
        team_id
      }
    }
  }
`);

export const GET_LOTS_LIST_COUNT = gql(`
  query GetLotsListCount($where: lots_bool_exp!) {
    lots_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`);

export const TOGGLE_PORTAL_LISTING = gql(`
  mutation TogglePortalListing($portal_id: uuid!, $lot_id: uuid!) {
    toggle_portal_listing(portal_id: $portal_id, lot_id: $lot_id) {
      id
      portal_id
      lot_id
      live
      milliseconds_online
    }
  }
`);

export const DELETE_LOT = gql(`
  mutation DeleteLot($id: uuid!) {
    delete_lots_by_pk(id: $id) {
      id
    }
  }
`);

export const GET_LISTING_DETAILS = gql(`
  query GetListingDetails($id: uuid!) {
    lots_by_pk(id: $id) {
      id
      ...ListingOverview
    }
  }
`);

export const GET_LISTING_EDIT_CONTEXT = gql(`
  query GetListingEditContext {
    property_types {
      id
      name
      label
      main_type
    }
    heating_enum {
      value
    }
    heating_distribution_enum {
      value
    }
    cooling_enum {
      value
    }
  }
`);

export const FILE_FRAGMENT = gql(`
  fragment File on files {
    id
    name
    url
    mimetype
    created_at
    uploader {
      id
      first_name
      last_name
    }
    document_type {
      id
      label
    }
  }
`);

export const UPDATE_LISTING = gql(`
  mutation updateListing($id: uuid!, $lot: lots_set_input) {
    update_lots_by_pk(pk_columns: { id: $id }, _set: $lot) {
      id
      ...ListingOverview
    }
  }
`);

export const CREATE_LISTING = gql(`
  mutation CreateListing($lot: InsertListingLotsInsertInput!) {
    insert_lots_derived(lot: $lot) {
      id
      lot {
        ...ListingCreationAddressStepFragment
      }
    }
  }
`);

export const CREATE_PROPERTY_FILE = gql(`
  mutation CreatePropertyFile($object: property_files_insert_input!) {
    insert_property_files_one(object: $object) {
      order_nr
      is_visible
      property_id
      file_id
      file {
        id
        name
        url
        mimetype
        created_at
      }
    }
  }
`);

export const UPDATE_LISTING_WITH_PROPERTY = gql(`
  mutation updateListingWithProperty(
    $id: uuid!
    $prop_id: uuid!
    $lot: lots_set_input!
    $property: properties_set_input!
  ) {
    update_lots_by_pk(pk_columns: { id: $id }, _set: $lot) {
      id
      ...ListingOverview
    }
    update_properties_by_pk(pk_columns: { id: $prop_id }, _set: $property) {
      id
      ...ListingOverviewProperty
    }
  }
`);

export const LISTING_DOCUMENTS = gql(`
  query ListingDocuments($lotId: uuid!, $type: dictionaries_types_enum_enum!) {
    lots_by_pk(id: $lotId) {
      id
      property_id
      lot_property_files(order_by: { order_nr: desc }) {
        property_id
        file_id
        is_visible
        order_nr
        file {
          ...File
        }
      }
    }
    document_types: dictionaries(where: { type: { _eq: $type } }, order_by: { label: asc }) {
      id
      label
    }
  }
`);

export const UPDATE_FILE = gql(`
  mutation UpdateFile($input: files_set_input!, $id: uuid!) {
    update_files(_set: $input, where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`);

export const UPDATE_PROPERTY_FILE = gql(`
  mutation UpdatePropertyFile(
    $input: property_files_set_input!
    $file_id: uuid!
    $property_id: uuid!
  ) {
    update_property_files(
      _set: $input
      where: { file_id: { _eq: $file_id }, property_id: { _eq: $property_id } }
    ) {
      returning {
        property_id
        file_id
        is_visible
        property_id
        file_id
      }
    }
  }
`);

export const REORDER_PROPERTY_FILES = gql(`
  mutation ReOrderPropertyFiles(
    $property_id: uuid!
    $file_id: uuid!
    $target_position: Int!
  ) {
    update_property_files(
      where: { property_id: { _eq: $property_id }, file_id: { _eq: $file_id } }
      _set: { order_nr: $target_position }
    ) {
      returning {
        property_id
        file_id
        order_nr
      }
      affected_rows
    }
  }
`);

export const INSERT_LOTS = gql(`
  mutation InsertLots($objects: [LotImportInput!]!) {
    import_lots(objects: $objects) {
      lot_id
    }
  }
`);

export const DELETE_LOTS = gql(`
  mutation DeleteLots($ids: [uuid!]!) {
    delete_lots(where: { id: { _in: $ids } }) {
      affected_rows
    }
  }
`);

export const EXPORT_LOTS = gql(`
  query ExportLots($where: lots_bool_exp!) {
    lots(where: $where) {
      id
      lot_reference
      status
      title
      description
      offer_type
      price_unit
      currency
      sale_price
      rent_extra
      rent_net
      monthly_rent_computed
      available_from
      brochure_title
      brochure_description
      video_url
      virtual_visit_url

      # Settings
      use_custom_brochure_description
      use_custom_brochure_title
      hide_price_on_brochure
      hide_price_on_portals
      hide_route
      hide_route_on_brochure
      send_enquiries_to_sellers
      origin_id
      shared_on_mls
      send_enquiries_to_sellers
      enquiry_response_enabled

      # Mandate
      mandate_type
      is_exclusive
      signed_at
      is_sales_tax_inclusive
      commission_rate
      duration_in_months
      fixed_fee
      has_success_fee
      order_nr
      mandate_sale_price
      minimum_net_seller_price
      success_fee_percentage
      expected_time_to_sell
      success_fee_custom
      success_fee_type

      broker {
        full_name
        emails(limit: 1) {
          email
        }
      }
      development_id

      created_at
      updated_at

      property {
        id
        ## Property type
        property_type {
          id
          name
          main_type
        }
        ## Address fields
        route
        street_number
        locality
        postcode
        state
        country
        country_code
        lat
        lng
        macro_location
        micro_location

        ## Surfaces
        living_surface
        usable_surface
        weighted_floor_surface
        gross_floor_surface
        built_surface
        land_surface
        balcony_surface
        terrace_surface
        garden_surface
        basement_surface
        commercial_surface
        residential_surface
        ceiling_height
        building_volume
        building_volume_norm

        ## Rooms & floors
        number_of_rooms
        number_of_bedrooms
        number_of_bathrooms
        number_of_toilets
        number_of_floors
        floor_of_flat

        ## Parking
        number_of_indoor_parkings
        number_of_outdoor_parkings
        parking_potential_rental_income
        parking_yearly_rental_income
        income_includes_parking

        ## Features
        number_of_lifts
        minergie_code
        orientation
        has_view
        has_fireplace
        has_sauna
        has_gym
        has_wellness_area
        has_photovoltaic_panels
        has_swimming_pool
        has_attic
        has_cellar
        has_storage_room
        has_elevator
        is_child_friendly
        is_commercial_fully_rented
        is_luxury
        is_maisonette
        is_parking_fully_rented
        is_rental
        is_residential_fully_rented
        is_secondary_residence
        is_wheelchair_accessible

        ## building
        construction_year
        renovation_year
        building_condition
        flat_condition
        plot_number
        plot_registry_number
        plot_is_constructible
        plot_has_constructible_reserve
        number_of_residential_units
        number_of_commercial_units

        ## Financials
        share_of_ownership
        residential_yearly_rental_income
        residential_potential_rental_income
        commercial_yearly_rental_income
        commercial_potential_rental_income
        renovation_fund

        ## Energy
        minergie_code
        heating
        cooling
        heating_distribution
        energy_certificate_version
        energy_consumption_class
        energy_reference_year
        energy_inspection_date
        emissions
        gas_emissions_class
        estimated_higher_energy_cost
        estimated_lower_energy_cost

        created_at
        updated_at

        property_images {
          id
          image {
            id
            url
          }
        }
      }
    }
  }
`);
