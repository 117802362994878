import { useState } from 'react';

import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import { Alert, Paper, Typography } from '@mui/material';

import { Markdown } from '../../../src/controls/markdown';
import type { ActivityFieldsFragment } from '../../__generated__/graphql';
import { TimeAgo } from '../TimeAgo';

import { ActivityIcon } from './ActivityIcon';
import { ActivityTitle } from './ActivityTitle';

export const Activity = ({
  activity,
}: {
  activity: ActivityFieldsFragment;
}) => {
  const [showJSON, setShowJSON] = useState(false);

  return (
    <TimelineItem key={activity?.id} style={{ fontSize: 10 }}>
      <TimelineSeparator>
        <TimelineConnector sx={{ height: '6px', flexGrow: 0 }} />
        <div onClick={() => setShowJSON(!showJSON)}>
          <ActivityIcon activity={activity} />
        </div>
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent sx={{ pr: 0 }}>
        <Typography>
          <ActivityTitle activity={activity} />
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          fontStyle={activity?.done_at ? 'italic' : 'normal'}
          sx={{ mb: 0.5 }}
        >
          <TimeAgo dateString={activity?.created_at} />
        </Typography>
        {activity?.activity_type === 'task' && activity?.subject && (
          <Typography>{activity?.subject}</Typography>
        )}
        {activity.note && (
          <Paper sx={{ p: 1.5 }} elevation={0} variant="outlined">
            <Markdown children={activity?.note} style={{ fontSize: 15 }} />
          </Paper>
        )}
        {activity.email_message?.snippet && (
          <Paper sx={{ p: 1.5 }} elevation={0} variant="outlined">
            <Typography
              fontWeight={600}
              fontSize={16}
              style={{
                maxWidth: 650,
                whiteSpace: 'nowrap',
                display: 'inline-block',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }}
            >
              {activity.email_message.subject}
            </Typography>
            <Markdown
              children={activity.email_message.snippet}
              style={{ fontSize: 15 }}
            />
            {activity.email_message.nylas_thread_id}
          </Paper>
        )}
        {showJSON && (
          <Paper sx={{ p: 1.5 }} elevation={0} variant="outlined">
            <Alert
              severity="info"
              sx={{
                fontSize: 12,
                fontFamily: 'monospace',
                position: 'relative',
                height: 500,
              }}
            >
              <pre
                style={{
                  overflow: 'auto',
                  position: 'absolute',
                  top: 20,
                  left: 40,
                  right: 0,
                  bottom: 0,
                }}
              >
                {JSON.stringify(activity, null, 2)}
              </pre>
            </Alert>
          </Paper>
        )}
      </TimelineContent>
    </TimelineItem>
  );
};
