import type { FieldPath } from 'react-hook-form';

import type { IntlLocale, Translate } from '../../../../src/hooks/locale';
import {
  type LotCurrency,
  getCurrencyByCountryCode,
} from '../../../../src/locale';
import type {
  Cooling_Enum_Enum,
  GetPropertyQuery,
  Heating_Distribution_Enum_Enum,
  Heating_Enum_Enum,
  Properties_Set_Input,
  PropertyFormDetailsFragment,
} from '../../../__generated__/graphql';
import { getCurrencySymbol, getRatingLabel } from '../../../utils/formatting';
import {
  isAppt,
  isCommercialBuilding,
  isHouseOrAppt,
  isMixedUseBuilding,
  isResidentialBuilding,
} from '../../../utils/propertyDataChecks';
import {
  getEnergyClassValues,
  getPropertyCoolingLabel,
  getPropertyHeatingDistributionLabel,
  getPropertyHeatingLabel,
  getPropertyOrientations,
  getPropertySides,
} from '../../../utils/propertyTemplateFunctions';
import { isLandTypeProperty } from '../../../utils/propertyTypes';
import {
  type FormDefinitionType,
  type FormFieldDefinitionType,
  createCategoryElement,
  createNumberFieldElement,
  createSelectElement,
  createTextFieldElement,
} from '../../form/RaForm';
import type { PropertyTypeLight } from '../propertyFormQueries';

import { createSurfaceFieldElement, getRatingChoiceOptions } from './utils';

export type PropertyFormDetailsData = Omit<
  PropertyFormDetailsFragment,
  '__property_type'
> & {
  __main_type?: string | null;
  __property_type?: PropertyTypeLight;
};

export type PropertyFormContext = {
  propertyTypes: GetPropertyQuery['property_types'];
  heatingTypes: GetPropertyQuery['heating_enum'];
  heatingDistribution: GetPropertyQuery['heating_distribution_enum'];
  coolingTypes: GetPropertyQuery['cooling_enum'];
};

type PropertyType = PropertyFormContext['propertyTypes'][number];

export const createPropertyTypeOptions = (
  propertyTypes: PropertyFormContext['propertyTypes'],
  filterCondition: (pt: PropertyType) => boolean,
  valueKey: keyof PropertyType,
): Array<{ value: string; label: string }> =>
  propertyTypes
    .filter(filterCondition)
    .map(pt => ({
      value: pt[valueKey] ?? '',
      label: pt.label ?? '',
    }))
    .sort((a, b) => (a.label ?? '').localeCompare(b.label ?? ''));

const getDetailsFields = (
  t: Translate,
  context: PropertyFormContext,
  useMinergieCode: boolean,
  currency: string | null | undefined,
  countryCode: string,
  locale: IntlLocale,
): FormFieldDefinitionType<PropertyFormDetailsData>[] => {
  const displayCurrency = (currency ??
    getCurrencyByCountryCode(countryCode)) as LotCurrency;

  const formDefinition: FormFieldDefinitionType<PropertyFormDetailsData>[] = [
    // *******************
    // PROPERTY TYPE
    // *******************
    createCategoryElement('property_type_title', t('Property type')),
    createSelectElement('__main_type', t('Property main type'), () =>
      createPropertyTypeOptions(
        context.propertyTypes,
        pt => pt.main_type == null,
        'name',
      ),
    ),
    createSelectElement(
      'property_type_id',
      t('Property type'),
      ({ __main_type }) =>
        createPropertyTypeOptions(
          context.propertyTypes,
          pt => pt.main_type === __main_type,
          'id',
        ),
    ),
    createTextFieldElement('plot_number', t('plotNumberLabel')),

    // *******************
    // SURFACES
    // *******************
    createCategoryElement('surfaces_title', t('Surfaces')),
    createSurfaceFieldElement('residential_surface', t('Residential surface')),
    createSurfaceFieldElement('commercial_surface', t('Commercial surface')),
    createSurfaceFieldElement('living_surface', t('Living surface')),
    createSurfaceFieldElement('usable_surface', t('Usable surface')),
    createSurfaceFieldElement('gross_floor_surface', t('Gross floor surface')),
    createSurfaceFieldElement('balcony_surface', t('Balcony surface')),
    createSurfaceFieldElement('terrace_surface', t('Terrace surface')),
    createSurfaceFieldElement('garden_surface', t('Garden surface')),
    createSurfaceFieldElement('land_surface', t('Land surface')),
    createSurfaceFieldElement('basement_surface', t('Basement surface')),
    createSurfaceFieldElement(
      'weighted_floor_surface',
      t('Weighted floor surface'),
    ),
    createSurfaceFieldElement('building_volume', t('buildingVolume')),
    createSurfaceFieldElement('built_surface', t('Built surface')),

    // *******************
    // UNITS
    // *******************
    createCategoryElement('units_title', t('Units')),
    createNumberFieldElement(
      'number_of_residential_units',
      t('numberOfResidentialUnits'),
      {
        min: 1,
        max: 500,
        gridProps: { xs: 6, sm: 6, md: 4 },
      },
    ),
    createNumberFieldElement(
      'number_of_commercial_units',
      t('numberOfCommercialUnits'),
      {
        min: 1,
        max: 500,
        gridProps: { xs: 6, sm: 6, md: 4 },
      },
    ),

    // *******************
    // ROOMS
    // *******************
    createCategoryElement('rooms_title', t('Rooms')),
    createNumberFieldElement('number_of_rooms', t('Number of rooms'), {
      gridProps: { xs: 6 },
      decimalNumbers: 1,
    }),
    createNumberFieldElement('number_of_bedrooms', t('Number of bedrooms'), {
      gridProps: { xs: 6 },
    }),
    createNumberFieldElement('number_of_bathrooms', t('Number of bathrooms'), {
      gridProps: { xs: 6 },
    }),
    createNumberFieldElement('number_of_toilets', t('Number of toilets'), {
      gridProps: { xs: 6 },
    }),

    // *******************
    // FLOORS
    // *******************
    createCategoryElement('floors_title', t('floors')),
    createNumberFieldElement('number_of_floors', t('numberOfFloors'), {
      min: 0,
      max: 50,
    }),
    createNumberFieldElement('floor_of_flat', t('floorOfFlat'), {
      min: 0,
      max: 50,
    }),
    createSelectElement('side', t('side'), () => getPropertySides(t)),
    createSelectElement('orientation', t('orientation'), () =>
      getPropertyOrientations(t),
    ),

    // *******************
    // PARKING
    // *******************
    createCategoryElement('parking_title', t('parking')),
    createNumberFieldElement('number_of_indoor_parkings', t('indoorParking'), {
      min: 0,
      gridProps: { xs: 6 },
    }),
    createNumberFieldElement(
      'number_of_outdoor_parkings',
      t('outdoorParking'),
      {
        min: 0,
        gridProps: { xs: 6 },
      },
    ),

    // *******************
    // HVAC
    // *******************
    createCategoryElement(
      'hvac_title',
      t('Heating, ventilation and air conditioning'),
    ),
    createSelectElement('heating', t('Heating'), () =>
      context.heatingTypes.map(he => ({
        value: he.value,
        label:
          getPropertyHeatingLabel(he.value as Heating_Enum_Enum, t) ?? he.value,
      })),
    ),
    createSelectElement('heating_distribution', t('Heating distribution'), () =>
      context.heatingDistribution.map(hed => ({
        value: hed.value,
        label:
          getPropertyHeatingDistributionLabel(
            hed.value as Heating_Distribution_Enum_Enum,
            t,
          ) ?? hed.value,
      })),
    ),
    createSelectElement('cooling', t('Cooling'), () =>
      context.coolingTypes.map(cool => ({
        value: cool.value,
        label:
          getPropertyCoolingLabel(cool.value as Cooling_Enum_Enum, t) ??
          cool.value,
      })),
    ),
  ];

  if (!useMinergieCode) {
    const currentYear = new Date().getFullYear();

    formDefinition.push(
      createCategoryElement('energy_efficiency_title', t('energyEfficiency')),
      createSelectElement(
        'energy_consumption_class',
        t('energyConsumptionClass'),
        () => getEnergyClassValues(t),
        { gridProps: { xs: 6, sm: 6, md: 4 } },
      ),
      createSelectElement(
        'gas_emissions_class',
        t('gasEmissionsClass'),
        () => getEnergyClassValues(t),
        { gridProps: { xs: 6, sm: 6, md: 4 } },
      ),
      createNumberFieldElement('consumption', t('consumption'), {
        suffix: 'kWh/m²/y',
        gridProps: { xs: 12, sm: 6, md: 4 },
      }),
      createNumberFieldElement('emissions', t('emissions'), {
        suffix: 'kg CO2/m²/y',
        gridProps: { xs: 12, sm: 6, md: 4 },
      }),
      createNumberFieldElement('energy_reference_year', t('estimateRefYear'), {
        max: currentYear,
        min: 1000,
        gridProps: { xs: 6, sm: 6, md: 4 },
        disableFormatting: true,
      }),
      {
        type: 'date',
        name: 'energy_inspection_date',
        label: t('inspectionDate'),
        gridProps: { xs: 6, sm: 6, md: 4 },
      },
      createNumberFieldElement(
        'estimated_lower_energy_cost',
        t('minEstimatedCostYear'),
        {
          prefix: getCurrencySymbol(displayCurrency, locale),
          decimalNumbers: 2,
          gridProps: { xs: 6, sm: 6, md: 4 },
        },
      ),
      createNumberFieldElement(
        'estimated_higher_energy_cost',
        t('maxEstimatedCostYear'),
        {
          prefix: getCurrencySymbol(displayCurrency, locale),
          decimalNumbers: 2,
          gridProps: { xs: 6, sm: 6, md: 4 },
        },
      ),
      createTextFieldElement(
        'energy_certificate_version',
        t('certificateVersion'),
        { gridProps: { xs: 6, sm: 6, md: 4 } },
      ),
    );
  }

  formDefinition.push(
    // *******************
    // CONSTRUCTION
    // *******************
    createCategoryElement('construction_title', t('Construction')),
    createNumberFieldElement('construction_year', t('Construction year'), {
      disableFormatting: true,
      gridProps: { xs: 6 },
    }),
    createNumberFieldElement('renovation_year', t('Renovation year'), {
      disableFormatting: true,
      gridProps: { xs: 6 },
    }),
    createSelectElement('building_standard', t('buildingStandard'), () =>
      getRatingChoiceOptions(t),
    ),
    createSelectElement('building_condition', t('buildingCondition'), () =>
      getRatingChoiceOptions(t),
    ),
  );

  if (useMinergieCode) {
    formDefinition.push(
      createSelectElement('minergie_code', t('minergieCode'), () => [
        { value: 5, label: t('fiveMinergiePEco') },
        { value: 4, label: t('fourMinergieEco') },
        { value: 3, label: t('threeMinergieP') },
        { value: 2, label: t('twoMinergie') },
        { value: 1, label: t('oneNotMinergie') },
        { value: null, label: t('notSet') },
      ]),
    );
  }

  formDefinition.push(
    // *******************
    // LOCATION
    // *******************
    createCategoryElement('location_title', t('location')),
    createNumberFieldElement('micro_location', t('microLocation'), {
      min: 1,
      max: 5,
      decimalNumbers: 1,
      helpText: ({ micro_location }) =>
        micro_location == null
          ? t('Enter a value between 1 to 5')
          : getRatingLabel(micro_location, t) ?? '',
    }),
    createNumberFieldElement('macro_location', t('macroLocation'), {
      min: 1,
      max: 5,
      decimalNumbers: 1,
      helpText: ({ macro_location }) =>
        macro_location == null
          ? t('Enter a value between 1 to 5')
          : getRatingLabel(macro_location, t) ?? '',
    }),
  );

  return formDefinition;
};

const isCommercial = (propertyType: PropertyTypeLight) =>
  isCommercialBuilding(propertyType) || isMixedUseBuilding(propertyType);

const isResidential = (propertyType: PropertyTypeLight) =>
  isResidentialBuilding(propertyType) || isMixedUseBuilding(propertyType);

export const propertyDetailsConditionalFields = new Map<
  | FieldPath<PropertyFormDetailsData>
  | 'rooms_title'
  | 'floors_title'
  | 'parking_title'
  | 'hvac_title'
  | 'energy_efficiency_title'
  | 'construction_title'
  | 'units_title',
  (data: PropertyFormDetailsData) => boolean
>([
  ['plot_number', ({ __main_type }) => __main_type === 'PROP'],
  [
    'living_surface',
    ({ __property_type }) =>
      isHouseOrAppt(__property_type) || isMixedUseBuilding(__property_type),
  ],
  [
    'usable_surface',
    ({ __property_type }) =>
      isHouseOrAppt(__property_type) ||
      isMixedUseBuilding(__property_type) ||
      isCommercialBuilding(__property_type) ||
      // Legacy to remove
      ['GASTRO', 'INDUS', 'PARK', 'SECONDARY'].includes(
        __property_type?.main_type ?? '',
      ),
  ],
  [
    'gross_floor_surface',
    ({ __property_type }) =>
      isHouseOrAppt(__property_type) ||
      isMixedUseBuilding(__property_type) ||
      isCommercialBuilding(__property_type) ||
      // Legacy to remove
      ['GASTRO', 'INDUS'].includes(__property_type?.main_type ?? ''),
  ],
  [
    'balcony_surface',
    ({ __property_type }) =>
      isHouseOrAppt(__property_type) ||
      isMixedUseBuilding(__property_type) ||
      isCommercialBuilding(__property_type),
  ],
  [
    'terrace_surface',
    ({ __property_type }) =>
      isHouseOrAppt(__property_type) ||
      isMixedUseBuilding(__property_type) ||
      isCommercialBuilding(__property_type),
  ],
  [
    'garden_surface',
    ({ __property_type }) =>
      isHouseOrAppt(__property_type) ||
      isMixedUseBuilding(__property_type) ||
      isCommercialBuilding(__property_type),
  ],
  ['land_surface', ({ __property_type }) => !isAppt(__property_type)],
  [
    'basement_surface',
    ({ __property_type }) =>
      isHouseOrAppt(__property_type) ||
      isMixedUseBuilding(__property_type) ||
      isCommercialBuilding(__property_type),
  ],
  [
    'weighted_floor_surface',
    ({ __property_type }) =>
      isHouseOrAppt(__property_type) ||
      isMixedUseBuilding(__property_type) ||
      isCommercialBuilding(__property_type),
  ],
  [
    'building_volume',
    ({ __property_type }) =>
      isHouseOrAppt(__property_type) ||
      isMixedUseBuilding(__property_type) ||
      isCommercialBuilding(__property_type) ||
      // Legacy to remove
      ['AGRI', 'GASTRO', 'INDUS', 'PARK'].includes(
        __property_type?.main_type ?? '',
      ),
  ],
  [
    'built_surface',
    ({ __property_type }) =>
      isHouseOrAppt(__property_type) ||
      isMixedUseBuilding(__property_type) ||
      isCommercialBuilding(__property_type) ||
      // Legacy to remove
      ['AGRI', 'GASTRO', 'INDUS', 'PARK'].includes(
        __property_type?.main_type ?? '',
      ),
  ],
  [
    'residential_surface',
    ({ __property_type }) => isResidential(__property_type),
  ],
  [
    'commercial_surface',
    ({ __property_type }) => isCommercial(__property_type),
  ],
  [
    'units_title',
    ({ __property_type }) =>
      isResidential(__property_type) || isCommercial(__property_type),
  ],
  [
    'number_of_residential_units',
    ({ __property_type }) => isResidential(__property_type),
  ],
  [
    'number_of_commercial_units',
    ({ __property_type }) => isCommercial(__property_type),
  ],
  [
    'rooms_title',
    ({ __property_type }) =>
      isHouseOrAppt(__property_type, true) || isCommercial(__property_type),
  ],
  [
    'number_of_rooms',
    ({ __property_type }) => isHouseOrAppt(__property_type, true),
  ],
  [
    'number_of_bedrooms',
    ({ __property_type }) => isHouseOrAppt(__property_type, true),
  ],
  [
    'number_of_bathrooms',
    ({ __property_type }) =>
      isHouseOrAppt(__property_type, true) || isCommercial(__property_type),
  ],
  [
    'number_of_toilets',
    ({ __property_type }) =>
      isHouseOrAppt(__property_type, true) || isCommercial(__property_type),
  ],
  [
    'floors_title',
    ({ __property_type }) => !isLandTypeProperty(__property_type),
  ],
  [
    'number_of_floors',
    ({ __property_type }) =>
      isHouseOrAppt(__property_type) ||
      isMixedUseBuilding(__property_type) ||
      isCommercialBuilding(__property_type) ||
      // Legacy to remove
      !isLandTypeProperty(__property_type),
  ],
  ['floor_of_flat', ({ __property_type }) => isAppt(__property_type)],
  ['side', ({ __property_type }) => isAppt(__property_type)],
  ['orientation', ({ __property_type }) => isAppt(__property_type)],
  [
    'parking_title',
    ({ __property_type }) => !isLandTypeProperty(__property_type),
  ],
  [
    'number_of_indoor_parkings',
    ({ __property_type }) =>
      isHouseOrAppt(__property_type) ||
      isMixedUseBuilding(__property_type) ||
      isCommercialBuilding(__property_type) ||
      // Legacy to remove
      !isLandTypeProperty(__property_type),
  ],
  [
    'number_of_outdoor_parkings',
    ({ __property_type }) =>
      isHouseOrAppt(__property_type) ||
      isMixedUseBuilding(__property_type) ||
      isCommercialBuilding(__property_type) ||
      // Legacy to remove
      !isLandTypeProperty(__property_type),
  ],
  ['hvac_title', ({ __property_type }) => !isLandTypeProperty(__property_type)],
  [
    'heating',
    ({ __property_type }) =>
      isHouseOrAppt(__property_type) ||
      isMixedUseBuilding(__property_type) ||
      isCommercialBuilding(__property_type) ||
      // Legacy to remove
      !isLandTypeProperty(__property_type),
  ],
  [
    'heating_distribution',
    ({ __property_type }) =>
      isHouseOrAppt(__property_type) ||
      isMixedUseBuilding(__property_type) ||
      isCommercialBuilding(__property_type) ||
      // Legacy to remove
      !isLandTypeProperty(__property_type),
  ],
  [
    'cooling',
    ({ __property_type }) =>
      isHouseOrAppt(__property_type) ||
      isMixedUseBuilding(__property_type) ||
      isCommercialBuilding(__property_type) ||
      // Legacy to remove
      !isLandTypeProperty(__property_type),
  ],
  [
    'energy_efficiency_title',
    ({ __property_type }) => !isLandTypeProperty(__property_type),
  ],
  [
    'energy_consumption_class',
    ({ __property_type }) =>
      isHouseOrAppt(__property_type) ||
      isMixedUseBuilding(__property_type) ||
      isCommercialBuilding(__property_type) ||
      // Legacy to remove
      !isLandTypeProperty(__property_type),
  ],
  [
    'gas_emissions_class',
    ({ __property_type }) =>
      isHouseOrAppt(__property_type) ||
      isMixedUseBuilding(__property_type) ||
      isCommercialBuilding(__property_type) ||
      // Legacy to remove
      !isLandTypeProperty(__property_type),
  ],
  [
    'consumption',
    ({ __property_type }) =>
      isHouseOrAppt(__property_type) ||
      isMixedUseBuilding(__property_type) ||
      isCommercialBuilding(__property_type) ||
      // Legacy to remove
      !isLandTypeProperty(__property_type),
  ],
  [
    'emissions',
    ({ __property_type }) =>
      isHouseOrAppt(__property_type) ||
      isMixedUseBuilding(__property_type) ||
      isCommercialBuilding(__property_type) ||
      // Legacy to remove
      !isLandTypeProperty(__property_type),
  ],
  [
    'energy_reference_year',
    ({ __property_type }) =>
      isHouseOrAppt(__property_type) ||
      isMixedUseBuilding(__property_type) ||
      isCommercialBuilding(__property_type) ||
      // Legacy to remove
      !isLandTypeProperty(__property_type),
  ],
  [
    'energy_inspection_date',
    ({ __property_type }) =>
      isHouseOrAppt(__property_type) ||
      isMixedUseBuilding(__property_type) ||
      isCommercialBuilding(__property_type) ||
      // Legacy to remove
      !isLandTypeProperty(__property_type),
  ],
  [
    'estimated_lower_energy_cost',
    ({ __property_type }) =>
      isHouseOrAppt(__property_type) ||
      isMixedUseBuilding(__property_type) ||
      isCommercialBuilding(__property_type) ||
      // Legacy to remove
      !isLandTypeProperty(__property_type),
  ],
  [
    'estimated_higher_energy_cost',
    ({ __property_type }) =>
      isHouseOrAppt(__property_type) ||
      isMixedUseBuilding(__property_type) ||
      isCommercialBuilding(__property_type) ||
      // Legacy to remove
      !isLandTypeProperty(__property_type),
  ],
  [
    'energy_certificate_version',
    ({ __property_type }) =>
      isHouseOrAppt(__property_type) ||
      isMixedUseBuilding(__property_type) ||
      isCommercialBuilding(__property_type) ||
      // Legacy to remove
      !isLandTypeProperty(__property_type),
  ],
  [
    'construction_title',
    ({ __property_type }) => !isLandTypeProperty(__property_type),
  ],
  [
    'construction_year',
    ({ __property_type }) =>
      isHouseOrAppt(__property_type) ||
      isMixedUseBuilding(__property_type) ||
      isCommercialBuilding(__property_type) ||
      // Legacy to remove
      !isLandTypeProperty(__property_type),
  ],
  [
    'renovation_year',
    ({ __property_type }) =>
      isHouseOrAppt(__property_type) ||
      isMixedUseBuilding(__property_type) ||
      isCommercialBuilding(__property_type) ||
      // Legacy to remove
      !isLandTypeProperty(__property_type),
  ],
  [
    'building_standard',
    ({ __property_type }) =>
      isHouseOrAppt(__property_type) ||
      isMixedUseBuilding(__property_type) ||
      isCommercialBuilding(__property_type) ||
      // Legacy to remove
      !isLandTypeProperty(__property_type),
  ],
  [
    'building_condition',
    ({ __property_type }) =>
      isHouseOrAppt(__property_type) ||
      isMixedUseBuilding(__property_type) ||
      isCommercialBuilding(__property_type) ||
      // Legacy to remove
      !isLandTypeProperty(__property_type),
  ],
  [
    'minergie_code',
    ({ __property_type }) =>
      isHouseOrAppt(__property_type) ||
      isMixedUseBuilding(__property_type) ||
      isCommercialBuilding(__property_type) ||
      // Legacy to remove
      !isLandTypeProperty(__property_type),
  ],
]);

export const getPropertyDetailsFormDefinition =
  (
    useMinergieCode: boolean,
    currency: string | null | undefined,
    countryCode: string,
    locale: IntlLocale,
  ): FormDefinitionType<PropertyFormDetailsData, PropertyFormContext> =>
  ({ t, context }) => {
    const fields = getDetailsFields(
      t,
      context,
      useMinergieCode,
      currency,
      countryCode,
      locale,
    );

    fields.forEach(field => {
      if ('name' in field) {
        const renderFn = propertyDetailsConditionalFields.get(
          field.name as any,
        );

        if (renderFn != null) {
          field.render = renderFn;
        }
      }
    });

    return fields;
  };

export const preparePropertyDetailsFormData = (
  data: PropertyFormDetailsData,
  useMinergieCode: boolean,
): Properties_Set_Input => {
  const {
    energy_consumption_class,
    gas_emissions_class,
    consumption,
    emissions,
    energy_reference_year,
    energy_inspection_date,
    estimated_lower_energy_cost,
    estimated_higher_energy_cost,
    energy_certificate_version,
    minergie_code,
    ...rest
  } = data;

  const payloadProperty: Properties_Set_Input = rest;

  if (useMinergieCode) {
    payloadProperty.minergie_code = minergie_code;
  } else {
    payloadProperty.energy_consumption_class = energy_consumption_class;
    payloadProperty.gas_emissions_class = gas_emissions_class;
    payloadProperty.consumption = consumption;
    payloadProperty.emissions = emissions;
    payloadProperty.energy_reference_year = energy_reference_year;
    payloadProperty.energy_inspection_date = energy_inspection_date;
    payloadProperty.estimated_lower_energy_cost = estimated_lower_energy_cost;
    payloadProperty.estimated_higher_energy_cost = estimated_higher_energy_cost;
    payloadProperty.energy_certificate_version = energy_certificate_version;
  }

  return Object.keys(payloadProperty).reduce(
    (acc: Properties_Set_Input, item): Properties_Set_Input => {
      const key = item as keyof typeof payloadProperty;
      const value = payloadProperty[key];
      acc[key] = value;

      const renderFn = propertyDetailsConditionalFields.get(key as any);
      if (renderFn && !renderFn(data)) {
        // We unset the value if the field is not rendered.
        acc[key] = null;
      }

      return acc;
    },
    {},
  );
};

export const getDefaultPropertyDetailsFormValues = (
  property: PropertyFormDetailsFragment,
): PropertyFormDetailsData => ({
  __main_type: property.__property_type?.main_type ?? '',
  __property_type:
    property.__property_type == null
      ? null
      : {
          name: property.__property_type.name,
          main_type: property.__property_type.main_type,
        },
  property_type_id: property.property_type_id,
  built_surface: property.built_surface,
  usable_surface: property.usable_surface,
  living_surface: property.living_surface,
  gross_floor_surface: property.gross_floor_surface,
  basement_surface: property.basement_surface,
  balcony_surface: property.balcony_surface,
  terrace_surface: property.terrace_surface,
  land_surface: property.land_surface,
  garden_surface: property.garden_surface,
  commercial_surface: property.commercial_surface,
  residential_surface: property.residential_surface,
  weighted_floor_surface: property.weighted_floor_surface,
  number_of_rooms: property.number_of_rooms,
  number_of_bedrooms: property.number_of_bedrooms,
  number_of_bathrooms: property.number_of_bathrooms,
  number_of_toilets: property.number_of_toilets,
  heating: property.heating,
  heating_distribution: property.heating_distribution,
  cooling: property.cooling,
  micro_location: property.micro_location,
  macro_location: property.macro_location,
  construction_year: property.construction_year,
  renovation_year: property.renovation_year,
  building_condition: property.building_condition,
  building_standard: property.building_standard,
  minergie_code: property.minergie_code,
  energy_certificate_version: property.energy_certificate_version,
  energy_reference_year: property.energy_reference_year,
  energy_inspection_date: property.energy_inspection_date,
  estimated_lower_energy_cost: property.estimated_lower_energy_cost,
  estimated_higher_energy_cost: property.estimated_higher_energy_cost,
  energy_consumption_class: property.energy_consumption_class,
  gas_emissions_class: property.gas_emissions_class,
  consumption: property.consumption,
  emissions: property.emissions,
  number_of_residential_units: property.number_of_residential_units,
  number_of_commercial_units: property.number_of_commercial_units,
  number_of_floors: property.number_of_floors,
  floor_of_flat: property.floor_of_flat,
  side: property.side,
  orientation: property.orientation,
  number_of_indoor_parkings: property.number_of_indoor_parkings,
  number_of_outdoor_parkings: property.number_of_outdoor_parkings,
  plot_number: property.plot_number,
  building_volume: property.building_volume,
});
