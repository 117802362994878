import { Tooltip, type TooltipProps } from '@mui/material';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';

import { useLocale } from '../../src/hooks/locale';

interface TimeAgoProps {
  dateString?: string | null;
  addSuffix?: boolean;
  ignoreTime?: boolean;
  tooltipPlacement?: TooltipProps['placement'];
}

export const TimeAgo = ({
  dateString,
  addSuffix = false,
  ignoreTime = false,
  tooltipPlacement = 'top',
}: TimeAgoProps) => {
  const { t, intlLocale, dateLocale } = useLocale();

  if (!dateString) {
    return null;
  }

  const now = new Date().getTime();
  const timestamp = new Date(dateString).getTime();
  let timeAgo: string;

  if (ignoreTime) {
    const secondsElapsed = Math.round((now - timestamp) / 1000);
    const hoursElapsed = Math.floor(secondsElapsed / 3600);

    if (hoursElapsed < 24) {
      timeAgo = t('today');
    } else {
      timeAgo = formatDistanceToNow(new Date(dateString), {
        addSuffix,
        locale: dateLocale,
      });
    }
  } else {
    timeAgo = formatDistanceToNow(new Date(dateString), {
      addSuffix,
      locale: dateLocale,
    });
  }

  const tooltipDate = new Intl.DateTimeFormat(intlLocale, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    ...(ignoreTime ? {} : { hour: 'numeric', minute: 'numeric' }),
  }).format(timestamp);

  return (
    <Tooltip title={tooltipDate} placement={tooltipPlacement} arrow={true}>
      <time>{timeAgo}</time>
    </Tooltip>
  );
};
