import { useState } from 'react';

import { gql, useMutation } from '@apollo/client';
import { Language } from '@mui/icons-material';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { refreshToken } from '@realadvisor/auth-client';

import { useLocale } from '../../src/hooks/locale';
import type { LanguageMenuUpdateUserMutation } from '../__generated__/graphql';
import { useAppData } from '../providers/AppDataProvider';

export const LanguageMenu = () => {
  const { t, language } = useLocale();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { me } = useAppData();
  const [updateUser] = useMutation<LanguageMenuUpdateUserMutation>(
    gql`
      mutation LanguageMenuUpdateUser($id: uuid!, $language: user_language!) {
        update_users_by_pk(
          pk_columns: { id: $id }
          _set: { language: $language }
        ) {
          id
          language
        }
      }
    `,
  );
  const changeLanguage = (newLanguage: String) => {
    if (language !== newLanguage) {
      updateUser({
        variables: {
          id: me?.id,
          language: newLanguage,
        },
        onCompleted: () => refreshToken().then(() => window.location.reload()),
      });
    }
  };
  return (
    <>
      <IconButton
        style={{ flexShrink: 0, margin: 4 }}
        title={t('language')}
        onClick={handleClick}
        color="inherit"
      >
        <Language />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem onClick={() => changeLanguage('en')}>English</MenuItem>
        <MenuItem onClick={() => changeLanguage('fr')}>Français</MenuItem>
        <MenuItem onClick={() => changeLanguage('de')}>Deutsch</MenuItem>
        <MenuItem onClick={() => changeLanguage('it')}>Italiano</MenuItem>
        <MenuItem onClick={() => changeLanguage('es')}>Español</MenuItem>
      </Menu>
    </>
  );
};
