import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

import type { GetUserQuery } from '../../__generated__/graphql';
import { InvoicesTable } from '../subscriptions/InvoicesTable';

interface UserSubscriptionsProps {
  user: GetUserQuery['users_by_pk'];
}

export const UserSubscriptions = ({ user }: UserSubscriptionsProps) => {
  const invoices = [];
  for (const subscription of user?.subscriptions || []) {
    for (const invoice of subscription.invoices || []) {
      invoices.push(invoice);
    }
  }

  return (
    <Grid container spacing={2} padding={2}>
      <Grid item xs={12}>
        <TableContainer component={Paper} elevation={0} variant="outlined">
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Created</TableCell>
                <TableCell>Current period start</TableCell>
                <TableCell>Current period end</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Total paid</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {user?.subscriptions?.map(subscription => (
                <TableRow key={subscription.id}>
                  <TableCell>{subscription.created.substring(0, 10)}</TableCell>
                  <TableCell>
                    {subscription.current_period_start.substring(0, 10)}
                  </TableCell>
                  <TableCell>
                    {subscription.current_period_end.substring(0, 10)}
                  </TableCell>
                  <TableCell>{subscription.status}</TableCell>
                  <TableCell align="right">
                    {Intl.NumberFormat().format(
                      subscription.total_amount / 100,
                    )}
                  </TableCell>
                  <TableCell align="right">
                    {Intl.NumberFormat().format(
                      (subscription.invoices_aggregate?.aggregate?.sum
                        ?.amount_paid ?? 0) / 100,
                    )}
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell colSpan={5}>
                  <strong>Total</strong>
                </TableCell>
                <TableCell align="right">
                  <strong>
                    {(
                      (user?.subscriptions?.reduce(
                        (acc, sub) =>
                          acc +
                          (sub.invoices_aggregate.aggregate?.sum?.amount_paid ??
                            0),
                        0,
                      ) || 0) / 100
                    )?.toLocaleString()}
                  </strong>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={12}>
        <InvoicesTable invoices={invoices} />
      </Grid>
    </Grid>
  );
};
