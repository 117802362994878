import { useEffect, useMemo, useState } from 'react';

import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { PeopleOutline } from '@mui/icons-material';
import {
  Autocomplete,
  Checkbox,
  CircularProgress,
  ListItem,
  ListItemIcon,
  ListItemText,
  debounce,
} from '@mui/material';

import {
  type GetTeamsByIdsQuery,
  type TeamFilterFragment,
  type TeamFilterSearchTeamsQuery,
} from '../../../__generated__/graphql';
import { useFiltersSearchParams } from '../useFiltersSearchParams';
import { useSchema } from '../useSchema';

import { FilterChip } from './FilterChip';
import type { QuickFilterProps } from './QuickFilters';
import { AutocompletePaper, AutocompleteTextField } from './QuickFilters';

const TEAM_FILTER_FRAGMENT = gql`
  fragment TeamFilter on teams {
    id
    name
  }
`;

const SEARCH_TEAMS = gql`
  ${TEAM_FILTER_FRAGMENT}
  query TeamFilterSearchTeams($q: String!) {
    teams(where: { name: { _ilike: $q } }, limit: 20) {
      id
      ...TeamFilter
    }
  }
`;

const GET_DEFAULT_TEAMS = gql`
  ${TEAM_FILTER_FRAGMENT}
  query GetTeamsByIds($ids: [uuid!]!) {
    teams(where: { id: { _in: $ids } }) {
      id
      ...TeamFilter
    }
  }
`;

interface UserMultiSelectProps {
  onClose?: () => void;
  onChange: (value: string[]) => void;
  defaultValue: string[];
}

export const TeamMultiSelect = ({
  onChange,
  onClose,
  defaultValue,
}: UserMultiSelectProps) => {
  const [options, setOptions] = useState<TeamFilterFragment[]>([]);
  const [searchTeams, { loading }] =
    useLazyQuery<TeamFilterSearchTeamsQuery>(SEARCH_TEAMS);
  const [value, setValue] = useState<TeamFilterFragment[]>([]);

  const { data: defaultTeams } = useQuery<GetTeamsByIdsQuery>(
    GET_DEFAULT_TEAMS,
    {
      skip: !defaultValue,
      variables: { ids: defaultValue },
    },
  );

  useEffect(() => {
    if (defaultTeams) {
      setValue(defaultTeams.teams);
    }
  }, [defaultTeams]);

  const debouncedSearch = useMemo(
    () =>
      debounce(value => {
        searchTeams({
          variables: {
            q: `%${value ?? ''}%`,
          },
        }).then(response => {
          const newOptions = response.data?.teams ?? [];
          setOptions(newOptions);
        });
      }, 300),
    [searchTeams],
  );

  const handleSearch = (value: string) => {
    if (value) {
      debouncedSearch(value);
    } else {
      setOptions([]);
    }
  };

  return (
    <Autocomplete
      multiple
      disableCloseOnSelect
      openOnFocus
      open
      disablePortal
      size="small"
      clearOnBlur={false}
      value={value}
      loading={loading}
      onClose={onClose}
      sx={{ width: 350 }}
      options={[...value, ...options]}
      getOptionLabel={option => option.name}
      filterOptions={x => x}
      PaperComponent={AutocompletePaper}
      onInputChange={(_, value) => handleSearch(value)}
      onChange={(_, value) => {
        onChange(value.map(team => team.id));
      }}
      renderInput={params => (
        <AutocompleteTextField
          {...params}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      renderOption={(props, option, { selected }) => (
        <ListItem {...props} key={option.id} dense>
          <ListItemIcon>
            <Checkbox
              edge="start"
              checked={selected}
              tabIndex={-1}
              disableRipple
            />
          </ListItemIcon>
          <ListItemText primary={option.name} />
        </ListItem>
      )}
    />
  );
};

export const TeamFilter = ({
  label,
  path,
  where,
  addWhereClause,
  deleteWhereClause,
}: QuickFilterProps) => {
  const [, setFiltersParams] = useFiltersSearchParams();
  const { getValueFromPath } = useSchema();
  const defaultValue = getValueFromPath(path, where)?.id?._in ?? [];

  const handleChange = (value: string[]) => {
    let newWhere = deleteWhereClause(where, path, true);
    if (value.length > 0) {
      newWhere = addWhereClause(newWhere, [...path, 'id', '_in'], value);
    }
    setFiltersParams(newWhere);
  };
  const handleDelete =
    defaultValue?.length > 0 ? () => handleChange([]) : undefined;

  return (
    <FilterChip
      label={label}
      icon={<PeopleOutline />}
      onDelete={handleDelete}
      renderFilter={({ handleClose }) => (
        <TeamMultiSelect
          onClose={handleClose}
          onChange={handleChange}
          defaultValue={defaultValue}
        />
      )}
    />
  );
};
