// @flow

import * as React from 'react';

import {
  FilledInput,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import { Image } from '@realadvisor/image';
import Downshift from 'downshift';
import { graphql } from 'react-relay';
import { Flex } from 'react-system';

import { InputPopup } from '../controls/popup';
import { useLocale } from '../hooks/locale';
import { useInputQuery } from '../hooks/relay';
import { useTheme } from '../hooks/theme';
import { Cancel } from '../icons/cancel';
import { Person } from '../icons/person';

import type {
  LotSearchFilters,
  lotInputQuery,
} from './__generated__/lotInputQuery.graphql';
import { type User } from './user-input';

export type Lot = $ReadOnly<{
  id: string,
  title: ?string,
  lotReference: ?string,
  salePrice: ?number,
  mandateType: 'commission_based' | 'fixed_fee',
  fixedFee: ?number,
  commissionRate: ?number,
  property: $ReadOnly<{
    formattedAddress: ?string,
    id: string,
    ...
  }>,
  primaryPropertyImage: ?$ReadOnly<{
    image: $ReadOnly<{
      url: ?string,
      ...
    }>,
    ...
  }>,
  broker: ?User,
  seller: ?User,
  offers: ?$ReadOnlyArray<{|
    +amount: number,
    +createdAt: string,
    +status: 'accepted' | 'pending' | 'refused',
  |}>,
  ...
}>;

type Props = {|
  Input?: React.AbstractComponent<any>,
  filters?: LotSearchFilters,
  value: ?Lot,
  clearable?: boolean,
  disabled?: boolean,
  onChange: (?Lot) => void,
  onBlur?: () => void,
|};
/* eslint-disable relay/unused-fields */
graphql`
  fragment lotInput_lot on Lot {
    id
    title
    lotReference
    salePrice
    mandateType
    fixedFee
    commissionRate
    property {
      id
      formattedAddress
    }
    primaryPropertyImage {
      image {
        url
      }
    }
    broker {
      ...userInput_user @relay(mask: false)
    }
    seller {
      ...userInput_user @relay(mask: false)
    }
    offers {
      status
      amount
      createdAt
    }
  }
`;
/* eslint-enable relay/unused-fields */

const getLotName = lot => {
  const lotReference = lot?.lotReference;
  const title = lot?.title;
  if (lotReference != null && title != null) {
    return `${lotReference} ${title}`;
  }
  if (lotReference != null) {
    return lotReference;
  }
  if (title != null) {
    return title;
  }
  return '';
};

const getItems = data =>
  (data?.lotsSearch?.edges ?? []).map(edge => edge?.node).filter(Boolean);

const LotAvatar = ({ lot, size }) => {
  const { colors } = useTheme();
  return (
    <Flex css={{ width: size, height: size, flexShrink: 0 }} mr={2}>
      {lot?.primaryPropertyImage?.image.url == null ? (
        <Person size={size} fill={colors.grey400} />
      ) : (
        <Image
          objectFit="cover"
          options={{ w: 40, h: 40, c: 'fill' }}
          src={lot.primaryPropertyImage.image.url}
          css={{ borderRadius: '50%' }}
        />
      )}
    </Flex>
  );
};

const LotMenu = ({ targetRef, items, downshift }) => {
  const { t } = useLocale();
  const { isOpen, highlightedIndex, getMenuProps, getItemProps } = downshift;
  return (
    <InputPopup referenceRef={targetRef} open={isOpen}>
      <List {...getMenuProps()}>
        {items.length === 0 && (
          <ListItem disabled={true}>{t('noOptions')}</ListItem>
        )}
        {isOpen &&
          items.map((item, index) => (
            <ListItem
              {...getItemProps({ item })}
              key={item.id}
              button={true}
              selected={highlightedIndex === index}
            >
              <LotAvatar lot={item} size={40} />
              <ListItemText
                primary={getLotName(item)}
                secondary={item.property.formattedAddress}
              />
            </ListItem>
          ))}
      </List>
    </InputPopup>
  );
};

export const LotInput = (props: Props): React.Node => {
  const [data, refetch] = useInputQuery<lotInputQuery>(
    graphql`
      query lotInputQuery($filters: LotSearchFilters) {
        lotsSearch(first: 20, filters: $filters) {
          edges {
            node {
              ...lotInput_lot @relay(mask: false)
            }
          }
        }
      }
    `,
  );

  const {
    Input = FilledInput,
    filters,
    clearable = true,
    disabled = false,
  } = props;
  const targetRef = React.useRef(null);
  return (
    <Downshift
      // make selectedItem always controlled
      selectedItem={props.value ?? null}
      itemToString={getLotName}
      onInputValueChange={inputValue =>
        refetch({ filters: { ...filters, search: inputValue } })
      }
      onChange={selectedItem => props.onChange(selectedItem)}
    >
      {downshift => {
        const { getInputProps, inputValue, selectedItem } = downshift;
        const { openMenu } = downshift;
        return (
          <div>
            <Input
              {...getInputProps({
                ref: targetRef,
                onFocus: () => {
                  refetch({ filters: { ...filters, search: inputValue } });
                  openMenu();
                },
                onBlur: props.onBlur,
                startAdornment: selectedItem != null && (
                  <InputAdornment position="start">
                    <LotAvatar lot={selectedItem} size={24} />
                  </InputAdornment>
                ),
                endAdornment: clearable && selectedItem && (
                  <InputAdornment position="end">
                    <IconButton onClick={() => props.onChange(null)}>
                      <Cancel />
                    </IconButton>
                  </InputAdornment>
                ),
                disabled,
              })}
            />
            <LotMenu
              targetRef={targetRef}
              items={getItems(data)}
              downshift={downshift}
            />
          </div>
        );
      }}
    </Downshift>
  );
};
