import parsePhoneNumberFromString from 'libphonenumber-js';

import type { Translate } from '../../src/hooks/locale';
import { type IntlLocale, type LotCurrency } from '../../src/locale';

type TAddress = {
  street_number?: string | null;
  route?: string | null;
  postcode?: string | null;
  locality?: string | null;
  state?: string | null;
  country?: string | null;
};

type TDummyAddress = {
  [K in keyof TAddress as `dummy_${K}`]: TAddress[K];
};

type TFormatAddressFnOptions = {
  hideRoute?: boolean | null;
  useDummyAddress?: boolean | null;
  dummyData?: TDummyAddress;
};

type TFormatAddressFn = {
  (
    address: TAddress,
    joinSeparator: string,
    shortFormat?: boolean,
    options?: TFormatAddressFnOptions,
  ): string;
  (
    address: TAddress,
    joinSeparator: null,
    shortFormat?: boolean,
    options?: TFormatAddressFnOptions,
  ): string[];
};

/**
 * Formats an address object into a string or string parts.
 *
 * @param param0 An address object
 * @param joinSeparator A separator to join the address parts. If `null`, the address parts will be returned as an array.
 * @param shortFormat Whether to use a short format (default) or a long format.
 */
export const formatAddress: TFormatAddressFn = (
  address,
  joinSeparator: string | null,
  shortFormat = true,
  options = {},
): any => {
  let { street_number, route, postcode, locality, state, country } = address;

  if (options.useDummyAddress === true) {
    street_number = options.dummyData?.dummy_street_number;
    route = options.dummyData?.dummy_route;
    postcode = options.dummyData?.dummy_postcode;
    locality = options.dummyData?.dummy_locality;
    state = options.dummyData?.dummy_state;
    country = options.dummyData?.dummy_country;
  }

  const addressParts = [
    options.hideRoute
      ? null
      : [route, street_number].filter(Boolean).join(' ').trim(),
    [postcode, locality].filter(Boolean).join(' ').trim(),
    !shortFormat ? [state, country].filter(Boolean).join(' - ') : null,
  ].filter(Boolean) as string[];

  return joinSeparator ? addressParts.join(joinSeparator) : addressParts;
};

export const formatCurrency = (
  value: number | null | undefined,
  currency: LotCurrency,
  locale: IntlLocale,
) =>
  value != null
    ? value.toLocaleString(locale, {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
        currency,
        style: 'currency',
      })
    : '';

export const formatCurrencyRange = (
  range:
    | {
        max?: number | null;
        min?: number | null;
      }
    | null
    | undefined,
  currency: LotCurrency,
  locale: IntlLocale,
) =>
  `${formatCurrency(range?.min, currency, locale)} - ${formatCurrency(
    range?.max,
    currency,
    locale,
  )}`;

export const formatIncome = (
  income: number,
  currency: LotCurrency,
  locale: IntlLocale,
) => {
  if (income < 1_000) {
    return income.toLocaleString(locale, {
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
      currency,
      style: 'currency',
    });
  }

  if (income < 1_000_000) {
    return (
      (income / 1_000).toLocaleString(locale, {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
        currency,
        style: 'currency',
      }) + 'K'
    );
  }

  return (
    (income / 1_000_000).toLocaleString(locale, {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
      currency,
      style: 'currency',
    }) + 'M'
  );
};

export const getCurrencySymbol = (currency: LotCurrency, locale: IntlLocale) =>
  new Intl.NumberFormat(locale, { style: 'currency', currency })
    .formatToParts(1)
    .find(x => x.type === 'currency')?.value ?? 'CHF';

export const roundRating = (rating?: number | null) => {
  if (!rating) {
    return 0;
  }

  return Math.round(rating * 10) / 10;
};

export const getRatingLabel = (
  rating: number | undefined | null | string,
  t: Translate,
) => {
  if (rating == null) {
    return null;
  }

  const ratingNumber = Math.round(
    typeof rating === 'string' ? Number.parseFloat(rating) : rating,
  );

  switch (ratingNumber) {
    case 1:
      return t('onePoor');
    case 2:
      return t('twoFair');
    case 3:
      return t('threeGood');
    case 4:
      return t('fourVeryGood');
    case 5:
      return t('fiveExcellent');
    default:
      return null;
  }
};

export const formatPhone = (phone?: string | null) => {
  const parsed = phone == null ? null : parsePhoneNumberFromString(phone, 'CH');
  const formatted = parsed == null ? null : parsed.formatInternational();
  return formatted;
};

/**
 * Formats the provided date string or timestamp into a human-readable date string.
 * @param locale The current locale of the user in which date should be formatted.
 * @param isoStrOrTimestamp Either an ISO Date string or a timestamp.
 * @returns A formatted date string in the user's locale.
 */
export const toHumanReadableDate = (
  locale: IntlLocale,
  isoStrOrTimestamp: string | number,
  formatOptions?: Intl.DateTimeFormatOptions,
) => {
  const options: Intl.DateTimeFormatOptions = {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    ...(formatOptions ?? {}),
  };

  return new Intl.DateTimeFormat(locale, options).format(
    typeof isoStrOrTimestamp === 'string'
      ? new Date(isoStrOrTimestamp)
      : isoStrOrTimestamp,
  );
};
