import * as React from 'react';

import { Typography } from '@material-ui/core';
import { type Group } from '@mui/icons-material';
import {
  Collapse,
  Divider,
  IconButton,
  List,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { endOfDay, startOfDay, subYears } from 'date-fns';
import {
  Link,
  NavLink,
  matchPath,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import type { To } from 'react-router-dom';
import { Box, Flex } from 'react-system';

import { AccountMenu } from '../../apollo/components/AccountMenu';
import { useAppData } from '../../apollo/providers/AppDataProvider';
import { toGlobalId } from '../../shared/global-id';
import { isRATenantId } from '../../src/utils/tenant';
import { useLocale } from '../hooks/locale';
import { Archery } from '../icons/archery';
import { ArcheryOutline } from '../icons/archery-outline';
import { ChevronLeft } from '../icons/chevron-left';
import { CmaReport } from '../icons/cma-report';
import { CmaReportOutline } from '../icons/cma-report-outline';
import { ExpandLess } from '../icons/expand-less';
import { ExpandMore } from '../icons/expand-more';
import { Lab } from '../icons/lab';
import { LabFilled } from '../icons/lab-filled';
import { NavBrokerage } from '../icons/nav-brokerage';
import { NavBrokerageActive } from '../icons/nav-brokerage-active';
import { NavCalendar } from '../icons/nav-calendar';
import { NavCalendarActive } from '../icons/nav-calendar-active';
import { NavDashboard } from '../icons/nav-dashboard';
import { NavDashboardActive } from '../icons/nav-dashboard-active';
import { NavEmail } from '../icons/nav-email';
import { NavEmailActive } from '../icons/nav-email-active';
import { NavPerformance } from '../icons/nav-performance';
import { NavPerformanceActive } from '../icons/nav-performance-active';
import { NavSaas } from '../icons/nav-saas';
import { NavSaasActive } from '../icons/nav-saas-active';
import { NavSettings } from '../icons/nav-settings';
import { NavSettingsActive } from '../icons/nav-settings-active';
import { NavUsers } from '../icons/nav-users';
import { NavUsersActive } from '../icons/nav-users-active';
import { Navigation } from '../icons/navigation';
import { NavigationOutline } from '../icons/navigation-outline';
import { PasswordAppear } from '../icons/password-appear';
import { PasswordAppearOutline } from '../icons/password-appear-outline';
import { PreviousQueue } from '../icons/previous-queue';

import { RealAdvisorLogo } from './realadvisor-logo';

type Props = {
  expanded: boolean;
  onChange: (value: boolean) => void;
};

type Group =
  | 'none'
  | 'leads'
  | 'brokerage'
  | 'contacts'
  | 'email'
  | 'performance'
  | 'saas'
  | 'settings'
  | 'v2'
  | 'legacy'
  | 'visibility';

const SidebarActions = ({ onToggle }: { onToggle: () => void }) => {
  const { t } = useLocale();
  const { me } = useAppData();

  const { palette } = useTheme();
  const backgroundColor = palette.sidebar;
  const isRATenant = isRATenantId(me?.tenant_id ?? '');
  const tenantName = me?.tenant.name;
  const logoUrl = me?.tenant.image?.url;

  return (
    <Box
      flexShrink={0}
      css={{ position: 'sticky', zIndex: 100, top: 0, backgroundColor }}
    >
      <Flex height="64px" alignItems="center" px={1}>
        {isRATenant && (
          <RealAdvisorLogo
            style={{
              width: 150,
              marginLeft: 12,
            }}
          />
        )}
        {!isRATenant && !logoUrl && (
          <Typography
            variant="body1"
            style={{
              color: palette.white.main,
              marginLeft: 12,
              fontWeight: 500,
            }}
            noWrap
          >
            {tenantName}
          </Typography>
        )}
        {!isRATenant && logoUrl && (
          <img
            src={logoUrl}
            alt="logo"
            style={{
              height: 54,
              borderRadius: 9,
              marginTop: 6,
              marginBottom: 6,
              width: 'calc(100% - 38px)',
              objectFit: 'contain',
              background: 'white',
            }}
          />
        )}

        <Box flexGrow={1} />
        <IconButton
          title={t('closeSideMenu')}
          color="inherit"
          onClick={onToggle}
        >
          <ChevronLeft />
        </IconButton>
      </Flex>

      <Divider />
    </Box>
  );
};

const Item = ({
  icon,
  children,
}: {
  icon: React.ReactNode;
  children: string;
}) => {
  const { text } = useTheme();

  return (
    <MenuItem
      disableRipple={true}
      disableGutters={true}
      sx={{
        height: 44,
      }}
    >
      <Tooltip title={children} placement="left">
        <ListItemIcon
          sx={{
            marginLeft: 2.2,
            marginRight: 1,
          }}
        >
          {icon}
        </ListItemIcon>
      </Tooltip>
      <ListItemText
        css={[
          {
            '.MuiTypography-root': {
              fontWeight: text.font.medium,
              color: 'white',
            },
          },
        ]}
        primary={children}
      />
    </MenuItem>
  );
};

const PerformanceGroupContent = ({
  isGroupActive,
  isFinancingActive,
  isLeadsActive,
}: {
  isGroupActive: (name: string) => boolean;
  isFinancingActive: boolean;
  isLeadsActive: boolean;
}) => {
  const { t } = useLocale();

  const now = Date.now();

  const activitiesParams = new URLSearchParams({
    startDate: startOfDay(now).getTime().toString(),
    endDate: endOfDay(now).getTime().toString(),
    activityStatus_in: 'pending',
  });

  const agentStatsParams = new URLSearchParams({
    startDate: subYears(now, 1).getTime().toString(),
    endDate: subYears(now, 0).getTime().toString(),
    sortBy: 'transactions_volume',
    sortDirection: 'desc',
  });

  return (
    <GroupContent active={isGroupActive('performance')}>
      {isLeadsActive && (
        <>
          <GroupItem to={`/performance/stats`}>{t('stats')}</GroupItem>
          <GroupItem
            to={{
              pathname: `/performance/agent-stats`,
              search: agentStatsParams.toString(),
            }}
          >
            {t('agentStats')}
          </GroupItem>
        </>
      )}
      {isFinancingActive && (
        <GroupItem to={`/performance/financing-stats`}>
          {t('financingStats')}
        </GroupItem>
      )}
      <GroupItem
        to={{
          pathname: `/performance/activities`,
          search: activitiesParams.toString(),
        }}
      >
        {t('activities')}
      </GroupItem>
    </GroupContent>
  );
};

const GroupHeading = ({
  icon,
  active,
  onToggle,
  children,
  to,
}: {
  icon: React.ReactNode;
  active: boolean;
  onToggle: () => void;
  children: string;
  to?: To;
}) => {
  const { text, palette } = useTheme();
  const navigate = useNavigate();
  return (
    <MenuItem
      selected={active}
      disableRipple={true}
      disableGutters={true}
      onClick={(event: { stopPropagation: () => void }) => {
        event.stopPropagation();
        onToggle();
        // Navigate to if not active
        if (to && !active) {
          navigate(to);
        }
      }}
      sx={{
        height: 44,
        marginLeft: 2.2,
        '&.Mui-selected': {
          backgroundColor: `${palette.sidebar} !important`,
        },
      }}
    >
      <Tooltip title={children} placement="left">
        <ListItemIcon sx={{ marginRight: 1 }}>{icon}</ListItemIcon>
      </Tooltip>
      <ListItemText
        css={[
          text.ellipsis,
          { '.MuiTypography-root': { fontWeight: text.font.medium } },
        ]}
        primary={children}
      />
      <ListItemIcon>
        {active ? (
          <ExpandLess fill={palette.white.main} size={22} />
        ) : (
          <ExpandMore fill={palette.white.main} size={22} />
        )}
      </ListItemIcon>
    </MenuItem>
  );
};

const GroupContent = ({
  active,
  children,
}: {
  active: boolean;
  children: React.ReactNode;
}) => {
  const { palette } = useTheme();
  const [hidden, setHidden] = React.useState(active === false);
  return (
    <Collapse
      aria-hidden={hidden}
      in={active}
      onEntered={() => setHidden(false)}
      onExit={() => setHidden(true)}
    >
      <div
        css={{
          backgroundColor: palette.sidebarSubMenu,
        }}
      >
        {children}
      </div>
    </Collapse>
  );
};

function getPathname(to: To): string | undefined {
  if (typeof to === 'string') {
    return to; // Assume the whole string is the pathname
  } else if (typeof to === 'object') {
    return to?.pathname; // Extract pathname from object
  }
}

const GroupItem = ({ to, children }: { to: To; children: string }) => {
  const location = useLocation();
  const { palette } = useTheme();
  const pathname = getPathname(to);
  const active = matchPath(pathname + '/*', location.pathname) != null;

  return (
    <MenuItem
      sx={{
        paddingLeft: 2,
        height: 38,
        '&.Mui-selected, &.Mui-selected:hover': {
          backgroundColor: palette.sidebarActive,
        },
      }}
      component={Link}
      selected={active}
      to={to}
      disableRipple={true}
      dense
    >
      <ListItemText
        css={{ paddingLeft: 0, whiteSpace: 'nowrap' }}
        primary={children}
      />
    </MenuItem>
  );
};

export const Sidebar = (props: Props) => {
  const location = useLocation();
  const { t } = useLocale();
  const { breakpoints, palette } = useTheme();
  const backgroundColor = palette.sidebar;
  const { expanded, onChange } = props;
  const isMobile = useMediaQuery(breakpoints.down('md'));

  const [group, setGroup] = React.useState<Group>('none');

  const isGroupActive = (name: string) => {
    return group === name;
  };
  const toggleGroup = (name: Group) => {
    if (expanded) {
      setGroup(v => (v === name ? 'none' : name));
    } else {
      onChange(true);
      setGroup(name);
    }
  };

  const { me } = useAppData();

  const isRATenant = isRATenantId(me?.tenant_id ?? '');

  const isAdmin = me?.is_admin === true;
  const isLeadsActive = me?.modules?.includes('leads') ?? false;
  const isFinancingActive =
    (isRATenant && me?.modules?.includes('financing')) ?? false;
  const isBrokerageActive = me?.modules?.includes('brokerage') ?? false;
  const isCMAReportsEnabled = me?.modules?.includes('cma_reports') ?? false;

  // only show for RA tenants
  const isVisibilityActive =
    isRATenant && (me?.show_in_agency_pages === true || isAdmin);
  const isProspectionMapActive =
    isRATenant && (me?.modules?.includes('prospection_map') || isAdmin);
  const brokerId = me == null ? '' : toGlobalId('User', me.id);
  const canEditSettings = me?.can_edit_settings === true;

  const NavItemDashboardCRM = (
    <NavLink to="/dashboard">
      {({ isActive }) => (
        <Item
          icon={
            isActive ? (
              <NavDashboardActive fill={palette.white.main} size={22} />
            ) : (
              <NavDashboard fill="none" size={22} />
            )
          }
        >
          {t('dashboard')}
        </Item>
      )}
    </NavLink>
  );

  const NavItemCalendar = (
    <NavLink
      to={
        isAdmin
          ? `/calendar?activityType_in=visit&activityStatus_in=done&activityStatus_in=pending`
          : `/calendar?activityType_in=visit&activityStatus_in=done&activityStatus_in=pending&brokerId_in=${brokerId}`
      }
    >
      {({ isActive }) => (
        <Item
          icon={
            isActive ? (
              <NavCalendarActive fill={palette.white.main} size={22} />
            ) : (
              <NavCalendar fill={palette.white.main} size={22} />
            )
          }
        >
          {t('calendar')}
        </Item>
      )}
    </NavLink>
  );

  const NavItemProspectionMap = (
    <NavLink to="/v2/maps">
      {({ isActive }) => (
        <Item
          icon={
            isActive ? (
              <Navigation fill={palette.white.main} size={22} />
            ) : (
              <NavigationOutline fill={palette.white.main} size={22} />
            )
          }
        >
          {t('prospectionMap')}
        </Item>
      )}
    </NavLink>
  );

  const NavItemLeads = (
    <NavLink
      to={{
        pathname: '/leads',
        search: isAdmin
          ? 'status_in=active&mostRecent=true&completed_in=true'
          : `mostRecent=true&sortBy=nextActivity.dueAt&sortDirection=asc&status_in=active&completed_in=true&brokerId_in=${brokerId}`,
      }}
    >
      {({ isActive }) => (
        <Item
          icon={
            isActive ? (
              <Archery fill={palette.white.main} size={22} />
            ) : (
              <ArcheryOutline fill={palette.white.main} size={22} />
            )
          }
        >
          {t('leads')}
        </Item>
      )}
    </NavLink>
  );

  const NavItemFinancingLeads = (
    <NavLink
      to={{
        pathname: `/users/${me?.id}` + '/financing',
        search: `completed_in=true&status_in=won&status_in=active`,
      }}
    >
      {({ isActive }) => (
        <Item
          icon={
            isActive ? (
              <Archery fill={palette.white.main} size={22} />
            ) : (
              <ArcheryOutline fill={palette.white.main} size={22} />
            )
          }
        >
          {t('financingLeads')}
        </Item>
      )}
    </NavLink>
  );

  const NavItemEmails = (
    <>
      <GroupHeading
        icon={
          matchPath('/emails/*', location.pathname) ||
          matchPath('/email-sequences/*', location.pathname) ? (
            <NavEmailActive fill={palette.white.main} size={22} />
          ) : (
            <NavEmail fill={palette.white.main} size={22} />
          )
        }
        active={isGroupActive('email')}
        onToggle={() => toggleGroup('email')}
      >
        {t('email')}
      </GroupHeading>

      {expanded && (
        <GroupContent active={isGroupActive('email')}>
          <GroupItem to={`/emails/inbox`}>{t('inbox')}</GroupItem>
          <GroupItem to={`/emails/sent`}>{t('sent')}</GroupItem>
          <GroupItem to={`/emails/templates`}>{t('templates')}</GroupItem>
          {
            // allow only for admins in initial release
            isAdmin && (
              <GroupItem to={`/email-sequences`}>
                {t('emailSequences')}
              </GroupItem>
            )
          }
          <GroupItem to={`/emails/campaigns`}>{t('massMail')}</GroupItem>
          <GroupItem to={`/emails/preferences`}>{t('settings')}</GroupItem>
        </GroupContent>
      )}
    </>
  );

  const NavItemCMA = (
    <NavLink to="/cma-reports">
      {({ isActive }) => (
        <Item
          icon={
            isActive ? (
              <CmaReport fill={palette.white.main} size={22} />
            ) : (
              <CmaReportOutline fill={palette.white.main} size={22} />
            )
          }
        >
          {t('CMA Reports')}
        </Item>
      )}
    </NavLink>
  );

  const NavItemContacts = (
    <>
      <GroupHeading
        icon={
          matchPath('/users/*', location.pathname) ||
          matchPath('/organisations/*', location.pathname) ||
          matchPath('/teams/*', location.pathname) ? (
            <NavUsersActive fill={palette.white.main} size={22} />
          ) : (
            <NavUsers fill={palette.white.main} size={22} />
          )
        }
        active={isGroupActive('contacts')}
        onToggle={() => toggleGroup('contacts')}
      >
        {t('contacts')}
      </GroupHeading>
      {expanded && (
        <GroupContent active={isGroupActive('contacts')}>
          <GroupItem to={`/users`}>{t('users')}</GroupItem>
          <GroupItem to={`/teams`}>{t('Agencies / Teams')}</GroupItem>
          {isAdmin && (
            <GroupItem to={`/organisations`}>{t('organisations')}</GroupItem>
          )}
        </GroupContent>
      )}
    </>
  );

  const NavItemBrokerage = (
    <>
      {me?.modules?.includes('brokerage') && (
        <GroupHeading
          icon={
            matchPath('/listings/*', location.pathname) ||
            matchPath('/developments/*', location.pathname) ||
            matchPath('/transactions/*', location.pathname) ||
            matchPath('/offers/*', location.pathname) ||
            matchPath('/enquiries/*', location.pathname) ||
            matchPath('/property-searches-v2/*', location.pathname) ||
            matchPath('/property-searches/*', location.pathname) ? (
              <NavBrokerageActive fill={palette.white.main} size={22} />
            ) : (
              <NavBrokerage fill={palette.white.main} size={22} />
            )
          }
          active={isGroupActive('brokerage')}
          onToggle={() => toggleGroup('brokerage')}
          to={{
            pathname: '/listings',
          }}
        >
          {t('brokerage')}
        </GroupHeading>
      )}
      {me?.modules?.includes('brokerage') && expanded && (
        <GroupContent active={isGroupActive('brokerage')}>
          <GroupItem
            to={{
              pathname: '/listings',
            }}
          >
            {t('Listings')}
          </GroupItem>
          <GroupItem to={`/developments`}>{t('Developments')}</GroupItem>
          <GroupItem to={`/enquiries`}>{t('Enquiries')}</GroupItem>
          {!isAdmin ? (
            <GroupItem to={`/property-searches`}>
              {t('Property searches')}
            </GroupItem>
          ) : (
            <GroupItem to={`/property-searches-v2`}>
              {t('Property searches')}
            </GroupItem>
          )}
          <GroupItem to={`/offers`}>{t('Offers')}</GroupItem>
          <GroupItem to={`/transactions`}>{t('Transactions')}</GroupItem>
        </GroupContent>
      )}
    </>
  );

  const NavItemsSettings = (
    <>
      {canEditSettings && (
        <>
          <GroupHeading
            icon={
              matchPath('/settings/*', location.pathname) ||
              matchPath('/docs/*', location.pathname) ? (
                <NavSettingsActive fill={palette.white.main} size={22} />
              ) : (
                <NavSettings fill={palette.white.main} size={22} />
              )
            }
            active={isGroupActive('settings')}
            onToggle={() => toggleGroup('settings')}
          >
            {t('settings')}
          </GroupHeading>
          {expanded && (
            <GroupContent active={isGroupActive('settings')}>
              <GroupItem to={`/settings/general`}>{t('general')}</GroupItem>
              <GroupItem to={`/settings/dictionaries`}>
                {t('dictionaries')}
              </GroupItem>
              <GroupItem to={`/settings/pipelines`}>{t('pipelines')}</GroupItem>
              <GroupItem to={`/settings/resources`}>{t('resources')}</GroupItem>
              <GroupItem to={`/settings/portals`}>{t('portals')}</GroupItem>
              <GroupItem to={`/settings/doc-templates`}>
                {t('templates')}
              </GroupItem>
              <GroupItem to={`/settings/webhook-subscriptions`}>
                {t('webhooks')}
              </GroupItem>
              {isFinancingActive && isAdmin && (
                <GroupItem to={`/settings/mortgage-rates`}>
                  {t('mortgageRates')}
                </GroupItem>
              )}
            </GroupContent>
          )}
        </>
      )}
    </>
  );

  const NavItemsSaaS = (
    <>
      <GroupHeading
        icon={
          matchPath('/subscriptions/*', location.pathname) ||
          matchPath('/stripe-subscriptions/*', location.pathname) ||
          matchPath('/invoices/*', location.pathname) ||
          matchPath('/checkout-pages/*', location.pathname) ? (
            <NavSaasActive fill={palette.white.main} size={22} />
          ) : (
            <NavSaas fill={palette.white.main} size={22} />
          )
        }
        active={isGroupActive('saas')}
        onToggle={() => toggleGroup('saas')}
      >
        {'SaaS'}
      </GroupHeading>
      {expanded && (
        <GroupContent active={isGroupActive('saas')}>
          <GroupItem to={`/subscriptions`}>{t('subscriptions')}</GroupItem>
          <GroupItem to={`/stripe-subscriptions`}>
            {t('stripeSubscriptions')}
          </GroupItem>
          <GroupItem to={`/invoices`}>{t('invoices')}</GroupItem>
          <GroupItem to={`/checkout-pages`}>{t('checkoutPages')}</GroupItem>
        </GroupContent>
      )}
    </>
  );

  const NavItemVisibility = (
    <>
      <GroupHeading
        icon={
          matchPath('/visibility/*', location.pathname) ? (
            <PasswordAppear fill={palette.white.main} size={22} />
          ) : (
            <PasswordAppearOutline fill={palette.white.main} size={22} />
          )
        }
        active={isGroupActive('visibility')}
        onToggle={() => toggleGroup('visibility')}
        to="/visibility/overview"
      >
        {t('Visibility')}
      </GroupHeading>
      {expanded && (
        <GroupContent active={isGroupActive('visibility')}>
          <GroupItem to={`/visibility/overview`}>{t('Overview')}</GroupItem>
          <GroupItem to={`/visibility/inbox`}>{t('Inbox')}</GroupItem>
          <GroupItem to={`/visibility/statistics`}>
            {t('Visibility Statistics')}
          </GroupItem>
          <GroupItem to={`/visibility/transactions`}>
            {t('Transactions')}
          </GroupItem>
        </GroupContent>
      )}
    </>
  );

  const NavItemPerformance = (
    <>
      <GroupHeading
        icon={
          matchPath('/performance/*', location.pathname) ? (
            <NavPerformanceActive fill={palette.white.main} size={22} />
          ) : (
            <NavPerformance fill={palette.white.main} size={22} />
          )
        }
        active={isGroupActive('performance')}
        onToggle={() => toggleGroup('performance')}
      >
        {t('performance')}
      </GroupHeading>
      {expanded && (
        <PerformanceGroupContent
          isGroupActive={isGroupActive}
          isFinancingActive={isFinancingActive}
          isLeadsActive={isLeadsActive}
        />
      )}
    </>
  );

  return (
    <>
      <SidebarActions onToggle={() => onChange(!expanded)} />

      <List
        css={{
          paddingTop: 0,
          paddingBottom: 50,
          flexGrow: 1,
        }}
        onClick={() => {
          if (isMobile) {
            onChange(false);
          }
        }}
      >
        {(isLeadsActive || isFinancingActive || isBrokerageActive) &&
          NavItemDashboardCRM}
        {isVisibilityActive && NavItemVisibility}
        {isProspectionMapActive && NavItemProspectionMap}
        {isCMAReportsEnabled && NavItemCMA}
        {isLeadsActive && !isFinancingActive && NavItemLeads}
        {!isLeadsActive && isFinancingActive && NavItemFinancingLeads}
        {isLeadsActive && isFinancingActive && (
          <>
            <GroupHeading
              icon={
                matchPath('/leads/*', location.pathname) ||
                matchPath('/financing/*', location.pathname) ? (
                  <Archery fill={palette.white.main} size={22} />
                ) : (
                  <ArcheryOutline fill={palette.white.main} size={22} />
                )
              }
              active={isGroupActive('leads')}
              onToggle={() => toggleGroup('leads')}
              to={{
                pathname: '/leads',
                search: 'status_in=active&mostRecent=true&completed_in=true',
              }}
            >
              {t('leads')}
            </GroupHeading>

            {expanded && (
              <GroupContent active={isGroupActive('leads')}>
                <GroupItem
                  to={{
                    pathname: '/leads',
                    search:
                      'status_in=active&mostRecent=true&completed_in=true',
                  }}
                >
                  {t('leads')}
                </GroupItem>
                <GroupItem to="/financing">{t('financingLeads')}</GroupItem>
              </GroupContent>
            )}
          </>
        )}
        {(isAdmin || isBrokerageActive) && NavItemBrokerage}
        {(isAdmin || isLeadsActive || isFinancingActive || isBrokerageActive) &&
          NavItemContacts}

        {(isAdmin || isLeadsActive || isFinancingActive || isBrokerageActive) &&
          NavItemCalendar}
        {(isAdmin || isLeadsActive || isFinancingActive || isBrokerageActive) &&
          NavItemEmails}
        {(isAdmin || isLeadsActive || isFinancingActive) && NavItemPerformance}
        {isAdmin && isRATenant && NavItemsSaaS}
        {isAdmin && NavItemsSettings}
        {(isAdmin || import.meta.env.NODE_ENV !== 'production') && (
          <>
            <GroupHeading
              icon={
                matchPath('/v2/*', location.pathname) ? (
                  <LabFilled fill={palette.white.main} size={22} />
                ) : (
                  <Lab fill={palette.white.main} size={22} />
                )
              }
              active={isGroupActive('v2')}
              onToggle={() => toggleGroup('v2')}
            >
              {t('v2')}
            </GroupHeading>
            {expanded && (
              <GroupContent active={isGroupActive('v2')}>
                <GroupItem
                  to={{
                    pathname: `/v2/leads`,
                    search: new URLSearchParams({
                      order_by: JSON.stringify([
                        {
                          requalified_or_created_at: 'desc',
                        },
                      ]),
                      where: JSON.stringify({
                        _or: [{ completed: { _eq: true } }],
                        status: { _in: ['active'] },
                      }),
                    }).toString(),
                  }}
                >
                  {t('Leads')}
                </GroupItem>
                <GroupItem to={`/v2/portals`}>{t('Portals')}</GroupItem>
                <GroupItem to={`/v2/listings`}>{t('Listings')}</GroupItem>
                <GroupItem to={`/v2/mls`}>{t('MLS')}</GroupItem>
                <GroupItem
                  to={{
                    pathname: `/v2/users`,
                    search: new URLSearchParams({
                      order_by: JSON.stringify([
                        {
                          created_at: 'desc',
                        },
                      ]),
                    }).toString(),
                  }}
                >
                  {t('Users')}
                </GroupItem>
                <GroupItem to={`/v2/tenant`}>{t('Tenant Settings')}</GroupItem>
                <GroupItem to={`/v2/organisations`}>
                  {t('Organisations')}
                </GroupItem>
                <GroupItem to={`/v2/transactions`}>
                  {t('Transactions')}
                </GroupItem>
                <GroupItem to={`/v2/offers`}>{t('Offers')}</GroupItem>
                <GroupItem
                  to={{
                    pathname: `/v2/subscriptions`,
                    search: new URLSearchParams({
                      order_by: JSON.stringify([
                        {
                          created_at: 'desc',
                        },
                      ]),
                    }).toString(),
                  }}
                >
                  {t('Subscriptions')}
                </GroupItem>
                <GroupItem
                  to={{
                    pathname: `/v2/enquiries`,
                    search: new URLSearchParams({
                      order_by: JSON.stringify([
                        {
                          created_at: 'desc',
                        },
                      ]),
                    }).toString(),
                  }}
                >
                  {t('Enquiries')}
                </GroupItem>
                <GroupItem
                  to={{
                    pathname: `/v2/property-searches`,
                    search: new URLSearchParams({
                      order_by: JSON.stringify([
                        {
                          created_at: 'desc',
                        },
                      ]),
                    }).toString(),
                  }}
                >
                  {t('Property searches')}
                </GroupItem>
                {isCMAReportsEnabled && (
                  <GroupItem to={`/v2/cma-reports`}>
                    {t('CMA Reports')}
                  </GroupItem>
                )}
              </GroupContent>
            )}
            <GroupHeading
              icon={<PreviousQueue fill={palette.white.main} size={22} />}
              active={isGroupActive('legacy')}
              onToggle={() => toggleGroup('legacy')}
            >
              {t('Legacy')}
            </GroupHeading>
            <GroupContent active={isGroupActive('legacy')}>
              <GroupItem
                to={{
                  pathname: `/v1/lots`,
                }}
              >
                {t('Listings')}
              </GroupItem>
              <GroupItem
                to={{
                  pathname: `/v1/users`,
                  search: new URLSearchParams({
                    order_by: JSON.stringify([
                      {
                        created_at: 'desc',
                      },
                    ]),
                  }).toString(),
                }}
              >
                {t('Users')}
              </GroupItem>
              <GroupItem
                to={{
                  pathname: `/v1/teams`,
                  search: new URLSearchParams({
                    order_by: JSON.stringify([
                      {
                        created_at: 'desc',
                      },
                    ]),
                  }).toString(),
                }}
              >
                {t('Teams')}
              </GroupItem>
              <GroupItem to={'/v1/transactions'}>{t('Transactions')}</GroupItem>
              {isCMAReportsEnabled && (
                <GroupItem to={'/v1/cma-reports'}>{t('CMA Reports')}</GroupItem>
              )}
            </GroupContent>
          </>
        )}
      </List>
      <div
        style={{
          position: 'sticky',
          bottom: 0,
          width: '100%',
          backgroundColor,
        }}
      >
        <Divider />
        <AccountMenu
          sx={{
            width: '100%',
          }}
        />
      </div>
    </>
  );
};
