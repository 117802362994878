import { gql } from '@apollo/client';

import { ORGANISATION_CELL_FRAGMENT } from '../../components/data-grid/OrganisationCell';
import {
  USER_INFO_ENQUIRY_FRAGMENT,
  USER_INFO_USER_FRAGMENT,
} from '../../components/data-grid/UserInfo';

export const TEAM_COMBINED_REVIEWS_AGG_FRAGMENT = gql`
  fragment TeamCombinedReviewsAgg on teams {
    combined_reviews_aggregate {
      aggregate {
        avg {
          rating
        }
        count
      }
    }
  }
`;

export const TEAM_PORTALS = gql`
  query GetTeamPortals($teamId: uuid!) {
    portals(where: { team_id: { _eq: $teamId } }) {
      id
      name
      active
      quota
      type
      portal_listings_aggregate {
        aggregate {
          count
        }
      }
    }
    teams_portals(
      order_by: { portal: { name: asc } }
      where: { team_id: { _eq: $teamId } }
    ) {
      id
      portal {
        id
        name
        active
        quota
        type
      }
    }
    portal_listings(
      where: {
        _and: [
          { live: { _eq: true } }
          { lot: { broker: { teams_users: { team_id: { _eq: $teamId } } } } }
        ]
      }
    ) {
      portal {
        id
      }
    }
  }
`;

export const DELETE_TEAM_PORTAL = gql`
  mutation DeleteTeamPortal($id: uuid!) {
    delete_teams_portals_by_pk(id: $id) {
      portal_id
      team_id
    }
  }
`;

export const TEAM_ADDRESS_FRAGMENT = gql`
  fragment TeamAddress on teams {
    route
    street_number
    postcode
    locality
    state
    country
  }
`;

export const TEAM_ADDRESS_LIGHT_FRAGMENT = gql`
  fragment TeamAddress_light on teams {
    route
    street_number
    postcode
    locality
  }
`;

export const GET_TEAMS_LIST = gql`
  ${ORGANISATION_CELL_FRAGMENT}
  ${TEAM_COMBINED_REVIEWS_AGG_FRAGMENT}
  ${TEAM_ADDRESS_LIGHT_FRAGMENT}
  query GetTeamsList(
    $where: teams_bool_exp!
    $order_by: [teams_order_by!]!
    $limit: Int!
    $offset: Int!
  ) {
    teams(where: $where, order_by: $order_by, limit: $limit, offset: $offset) {
      id
      created_at
      name
      ...TeamAddress_light
      lat
      lng
      show_in_agency_pages
      agency_slug
      google_place_description
      ...TeamCombinedReviewsAgg
      google_reviews_aggregate {
        aggregate {
          avg {
            rating
          }
          count
        }
      }
      logo {
        id
        url
      }
      organisation {
        id
        ...OrganisationCell_organisation
      }
      teams_users {
        user {
          id
          first_name
          last_name
          full_name
          combined_reviews_aggregate(where: { type: { _eq: "native" } }) {
            aggregate {
              avg {
                rating
              }
              count
            }
          }
          subscriptions {
            status
            total_amount
          }
          lead_agents_aggregate(where: { source: { _eq: result_page } }) {
            aggregate {
              count
            }
          }
        }
      }
      property_transactions_aggregate {
        aggregate {
          count
          max {
            updated_at
          }
        }
      }
    }
  }
`;

export const GET_TEAMS_LIST_COUNT = gql`
  query GetTeamsListCount($where: teams_bool_exp!) {
    teams_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

export const DRAWER_GET_TEAM = gql`
  query DrawerGetTeam($id: uuid!) {
    teams_by_pk(id: $id) {
      id
      created_at
      name
      teams_users_aggregate {
        nodes {
          user_id
        }
      }
    }
  }
`;

export const OVERVIEW_GET_TEAM = gql`
  ${USER_INFO_USER_FRAGMENT}
  ${TEAM_COMBINED_REVIEWS_AGG_FRAGMENT}
  query OverviewGetTeam($id: uuid!, $includeSubscriptions: Boolean!) {
    teams_by_pk(id: $id) {
      id
      created_at
      name
      cover_photo {
        id
        url
      }
      logo {
        id
        url
      }
      ...TeamCombinedReviewsAgg
      teams_users(order_by: { order_nr: asc }) {
        created_at
        is_leader
        title
        deactivated
        order_nr
        user {
          id
          is_broker
          is_admin
          combined_reviews_aggregate(where: { type: { _eq: "native" } }) {
            aggregate {
              avg {
                rating
              }
              count
            }
          }
          ...UserInfo_user
          subscriptions @include(if: $includeSubscriptions) {
            total_amount
            status
            current_period_start
            current_period_end
            invoices_aggregate {
              aggregate {
                sum {
                  amount_paid
                }
              }
            }
          }
          broker_leads_aggregate {
            aggregate {
              count
            }
          }
          lots_aggregate {
            aggregate {
              count
            }
          }
          property_transactions_aggregate {
            aggregate {
              sum {
                purchase_price
              }
            }
          }
          reviews_aggregate(where: { completed: { _eq: true } }) {
            aggregate {
              avg {
                rating
              }
              count
            }
          }
        }
      }
    }
  }
`;

export const CREATE_TEAM = gql`
  mutation CreateTeam($team: teams_insert_input!) {
    insert_teams_one(object: $team) {
      id
    }
  }
`;

export const DELETE_TEAM = gql`
  mutation DeleteTeam(
    $teamId: uuid!
    $userIds: [uuid!]!
    $userIdsString: _uuid
  ) {
    delete_teams_by_pk(id: $teamId) {
      id
    }
    # reset default team for user if same as deleted one
    update_users(
      where: { id: { _in: $userIds }, default_team_id: { _eq: $teamId } }
      _set: { default_team_id: null }
    ) {
      returning {
        default_team_id
      }
    }
    # delete team users
    delete_teams_users(
      where: { team_id: { _eq: $teamId }, user_id: { _in: $userIds } }
    ) {
      affected_rows
    }

    # disable broker when all teams are deleted/deactivated
    disable_broker: update_users(
      where: {
        id: { _in: $userIds }
        _not: { teams_users: { deactivated: { _eq: false } } }
      }
      _set: { is_broker: false }
    ) {
      returning {
        is_broker
      }
    }
    set_default_team(args: { user_ids: $userIdsString }) {
      id
    }
  }
`;

export const DELETE_TEAM_USER = gql`
  mutation DeleteTeamUser($team_id: uuid!, $user_id: uuid!) {
    # reset default team for user if same as deleted one
    update_users(
      where: { id: { _eq: $user_id }, default_team_id: { _eq: $team_id } }
      _set: { default_team_id: null }
    ) {
      returning {
        default_team_id
        teams_users {
          team {
            id
          }
          deactivated
        }
      }
    }
    delete_teams_users(
      where: { team_id: { _eq: $team_id }, user_id: { _eq: $user_id } }
    ) {
      affected_rows
    }
    # disable broker when all teams are deleted
    disable_broker: update_users(
      where: {
        id: { _eq: $user_id }
        _not: { teams_users: { deactivated: { _eq: false } } }
      }
      _set: { is_broker: false }
    ) {
      returning {
        is_broker
      }
    }
  }
`;

export const ADD_TEAM_USER = gql`
  mutation AddTeamUser($team_id: uuid!, $user_id: uuid!) {
    # set default team if not set
    set_default_team_id: update_users(
      where: { id: { _eq: $user_id }, default_team_id: { _is_null: true } }
      _set: { default_team_id: $team_id }
    ) {
      returning {
        default_team_id
      }
    }

    insert_teams_users_one(
      object: { team_id: $team_id, user_id: $user_id, order_nr: 1000 }
    ) {
      team {
        id
      }
      user {
        id
      }
    }
    # enable broker when at least one team is added
    enable_broker: update_users(
      where: { id: { _eq: $user_id }, teams_users: {} }
      _set: { is_broker: true }
    ) {
      returning {
        is_broker
      }
    }
  }
`;

export const REORDER_TEAM_USERS = gql`
  mutation UpdateTeamUserOrder(
    $team_id: uuid!
    $user_id: uuid!
    $target_position: Int!
  ) {
    update_teams_users(
      where: { team_id: { _eq: $team_id }, user_id: { _eq: $user_id } }
      _set: { order_nr: $target_position }
    ) {
      returning {
        user_id
        order_nr
      }
      affected_rows
    }
  }
`;

export const UPDATE_TEAM_USER = gql`
  mutation UpdateTeamUser(
    $user_id: uuid!
    $team_id: uuid!
    $team_user: teams_users_set_input!
  ) {
    update_teams_users(
      where: { team_id: { _eq: $team_id }, user_id: { _eq: $user_id } }
      _set: $team_user
    ) {
      returning {
        is_leader
      }
    }
    # enable broker when at least one team is activated
    enable_broker: update_users(
      where: {
        id: { _eq: $user_id }
        teams_users: { deactivated: { _eq: false } }
      }
      _set: { is_broker: true }
    ) {
      returning {
        is_broker
        default_team_id
        teams_users {
          team {
            id
          }
          deactivated
        }
      }
    }
    # disable broker when all teams are deactivated
    disable_broker: update_users(
      where: {
        id: { _eq: $user_id }
        _not: { teams_users: { deactivated: { _eq: false } } }
      }
      _set: { is_broker: false }
    ) {
      returning {
        is_broker
      }
    }
  }
`;

export const TEAMS_CARD_QUERY = gql`
  query TeamsCard($userId: uuid!) {
    users(where: { id: { _eq: $userId } }) {
      default_team_id
      teams_users(order_by: { team: { name: asc_nulls_last } }) {
        deactivated
        is_leader
        team {
          id
          name
        }
      }
    }
  }
`;

export const TEAMS_CARD_UPDATE_USER_DEFAULT_TEAM_MUTATION = gql`
  mutation TeamsCardUpdateUserDefaultTeam($user_id: uuid!, $team_id: uuid!) {
    update_users(
      where: { id: { _eq: $user_id } }
      _set: { default_team_id: $team_id }
    ) {
      returning {
        default_team_id
      }
    }
  }
`;

export const DELETE_TEAM_IMAGE = gql`
  mutation DeleteTeamImage($id: uuid!) {
    delete_team_images_by_pk(id: $id) {
      id
    }
  }
`;

const TEAM_SETTINGS_FRAGMENT = gql`
  ${TEAM_ADDRESS_FRAGMENT}
  fragment TeamSettingsFragment on teams {
    id
    branding_color
    created_at
    created_by
    name
    description
    website_url
    do_follow_link
    legal_name
    agency_slug
    show_in_agency_pages
    ...TeamAddress
    country_code
    lat
    lng
    google_place_id
    google_place_description
    organisation_id
    facebook_url
    instagram_url
    youtube_url
    linkedin_url
    twitter_url
    phone_numbers(order_by: [{ primary: desc }, { verified: desc }]) {
      id
      number
    }
  }
`;

export const GET_TEAM_SETTINGS = gql`
  ${TEAM_SETTINGS_FRAGMENT}
  query getTeamSettings($id: uuid!) {
    teams_by_pk(id: $id) {
      id
      ...TeamSettingsFragment
    }
  }
`;

export const UPDATE_TEAM_SETTINGS = gql`
  ${TEAM_SETTINGS_FRAGMENT}
  mutation updateTeamSettings($id: uuid!, $team: teams_set_input!) {
    update_teams_by_pk(pk_columns: { id: $id }, _set: $team) {
      id
      cover_photo {
        id
        url
      }
      logo {
        id
        url
      }
      ...TeamSettingsFragment
    }
  }
`;

export const UPDATE_TEAM_PHONE_NUMBER = gql`
  mutation updateTeamPhoneNumber($id: uuid!, $phone_number: String!) {
    delete_phone_numbers(where: { team_id: { _eq: $id } }) {
      affected_rows
    }
    insert_phone_numbers_one(object: { team_id: $id, number: $phone_number }) {
      id
      number
    }
  }
`;

export const UPDATE_TEAM_IMAGES = gql`
  ${TEAM_SETTINGS_FRAGMENT}
  mutation updateTeamImages($id: uuid!, $team: teams_set_input!) {
    update_teams_by_pk(pk_columns: { id: $id }, _set: $team) {
      id
      cover_photo {
        id
        url
      }
      logo {
        id
        url
      }
      ...TeamSettingsFragment
    }
  }
`;

export const GET_TEAM_VALUATION_REQUESTS = gql`
  ${USER_INFO_USER_FRAGMENT}
  query GetTeamValuationRequests($teamId: uuid!) {
    lead_agents(
      where: {
        user: { teams_users: { team_id: { _eq: $teamId } } }
        source: { _eq: result_page }
      }
      order_by: { created_at: desc }
    ) {
      id
      created_at
      user {
        id
        ...UserInfo_user
      }
      lead {
        id
        appraisal_reason
        sale_horizon
        contact {
          id
          first_name
          last_name
          phone_numbers {
            id
            number
          }
          emails {
            id
            email
          }
        }
      }
    }
  }
`;

export const GET_TEAM_CONTACT_REQUESTS = gql`
  ${USER_INFO_USER_FRAGMENT}
  ${USER_INFO_ENQUIRY_FRAGMENT}
  query GetTeamContactRequests($teamId: uuid!) {
    enquiries(
      where: {
        _or: [
          { broker: { teams_users: { team_id: { _eq: $teamId } } } }
          { team_id: { _eq: $teamId } }
        ]
      }
      order_by: { created_at: desc }
    ) {
      id
      created_at
      contact_reason
      first_name
      last_name
      email_address
      phone_number
      message
      ...UserInfo_enquiry
      broker {
        id
        ...UserInfo_user
      }
      property_transaction {
        signature_date
        property {
          id
          route
          street_number
          postcode
          locality
        }
      }
    }
  }
`;
