import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';

import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { GpsFixed } from '@mui/icons-material';
import {
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemText,
  type SxProps,
  type Theme,
  debounce,
} from '@mui/material';

import { useLocale } from '../../src/hooks/locale';
import type {
  LeadSelectFragment,
  SearchLeadsQuery,
} from '../__generated__/graphql';
import { formatAddress } from '../utils/formatting';

import { RaAutoComplete } from './data-grid/RaAutoComplete';

const LEAD_SELECT_FRAGMENT = gql`
  fragment LeadSelect on leads {
    id
    property {
      route
      street_number
      postcode
      locality
    }
    contact {
      id
      first_name
      last_name
    }
  }
`;

const SEARCH_LEADS = gql`
  ${LEAD_SELECT_FRAGMENT}
  query SearchLeads($q: String!, $where: leads_bool_exp!) {
    search_leads_tsv(
      args: { search_text: $q }
      limit: 10
      where: $where
      order_by: { created_at: desc }
    ) {
      id
      ...LeadSelect
    }
  }
`;

const GET_DEFAULT_LEAD = gql`
  ${LEAD_SELECT_FRAGMENT}
  query GetDefaultLead($id: uuid!) {
    leads_by_pk(id: $id) {
      id
      ...LeadSelect
    }
  }
`;

interface LeadSelectProps {
  leadId?: string;
  onChange?: (lead: LeadSelectFragment | null) => void;
  placeholder?: string;
  sx?: SxProps<Theme>;
  autoFocus?: boolean;
}

const getContactName = ({
  firstName,
  lastName,
}: {
  firstName?: string | null;
  lastName?: string | null;
}) => {
  if (firstName && lastName) {
    return `${firstName} ${lastName}`;
  }
  return firstName ?? lastName ?? null;
};

export const LeadSelect = ({
  onChange,
  placeholder,
  leadId,
  autoFocus = false,
  sx,
}: LeadSelectProps) => {
  const { t } = useLocale();
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = useState<LeadSelectFragment[]>([]);
  const [selectedValue, setSelectedValue] = useState<LeadSelectFragment | null>(
    null,
  );

  const { data: defaultLead } = useQuery(GET_DEFAULT_LEAD, {
    skip: !leadId,
    variables: { id: leadId },
  });

  useEffect(() => {
    if (defaultLead) {
      setSelectedValue(defaultLead.leads_by_pk);
    }
  }, [defaultLead]);

  const [searchLeads, { loading, error }] = useLazyQuery<SearchLeadsQuery>(
    SEARCH_LEADS,
    {
      variables: {
        where: {
          completed: {
            _eq: true,
          },
        },
      },
    },
  );

  const debouncedSearch = useMemo(
    () =>
      debounce(value => {
        if (value == null || value.length < 3) {
          return;
        }
        searchLeads({
          variables: {
            q: value,
          },
        }).then(response => {
          setOpen(true);
          setOptions(response.data?.search_leads_tsv ?? []);
        });
      }, 275),
    [searchLeads],
  );

  return (
    <RaAutoComplete<LeadSelectFragment>
      sx={sx}
      value={selectedValue}
      getOptionLabel={option =>
        `${getContactName({
          firstName: option.contact?.first_name,
          lastName: option.contact?.last_name,
        })} ${option.property && formatAddress(option.property, ', ')}`
      }
      open={open}
      autoFocus={autoFocus}
      options={options}
      InputProps={{
        placeholder: placeholder ?? t('Search leads'),
      }}
      loading={loading}
      setOpen={setOpen}
      setOptions={setOptions}
      debouncedSearch={debouncedSearch}
      onClose={() => {
        setOpen(false);
        setOptions([]);
      }}
      renderOption={(props, option) => (
        <ListItem
          {...props}
          key={option.id}
          sx={{
            padding: 0,
          }}
          style={{
            padding: '1px 10px',
          }}
        >
          <ListItemAvatar>
            <Avatar>
              <GpsFixed />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            sx={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
            primary={getContactName({
              firstName: option.contact?.first_name,
              lastName: option.contact?.last_name,
            })}
            secondary={formatAddress(
              {
                street_number: option.property?.street_number,
                route: option.property?.route,
                postcode: option.property?.postcode,
                locality: option.property?.locality,
              },
              ', ',
            )}
          />
        </ListItem>
      )}
      onChange={(_event, value) => {
        setSelectedValue(value);
        onChange?.(value ?? null);
      }}
      error={error != null}
    />
  );
};
