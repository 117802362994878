import { gql, useQuery } from '@apollo/client';
import AutoStoriesOutlinedIcon from '@mui/icons-material/AutoStoriesOutlined';
import { FormLabel, LinearProgress, Stack } from '@mui/material';

import { toGlobalId } from '../../../shared/global-id';
import { type GetListingDocumentTemplatesQuery } from '../../__generated__/graphql';
import { type FormFieldDefinitionType, RaForm } from '../form/RaForm';
import { EEditListingForms } from '../listing-overview-form/ListingOverviewEditModal';
import {
  type TAvailabilityFormData,
  type TListedByFormData,
  useListingOverviewEditFormDefinition,
} from '../listing-overview-form/listingOverviewFormDefinition';
import { type LightFormDefinition } from '../listing-overview-form/models';
import { TemplatesListButtonGroup } from '../TemplatesListButtonGroup';

import { type ListingCreationStepComponent } from './CreateListingStepper';

const GET_DOCUMENT_TEMPLATES = gql`
  query GetListingDocumentTemplates {
    lot_brochure_doc_templates: doc_templates(
      limit: 100
      where: { type: { _eq: lot_brochure } }
    ) {
      id
      name
      language
      is_default
      is_shown_in_ui
      is_admin_default
    }
  }
`;

export const ListingAvailabilityStep: ListingCreationStepComponent = ({
  footerActionsComponent,
  onFormSubmitted,
  listing,
}) => {
  const { data: documentTemplatesData } =
    useQuery<GetListingDocumentTemplatesQuery>(GET_DOCUMENT_TEMPLATES);
  const availabilityFormDefinition = useListingOverviewEditFormDefinition({
    listing,
    formType: EEditListingForms.AVAILABILITY,
  });

  const agentFormDefinition = useListingOverviewEditFormDefinition({
    listing,
    formType: EEditListingForms.LISTED_BY,
  });

  if (availabilityFormDefinition == null || agentFormDefinition == null) {
    return null;
  }

  if (documentTemplatesData == null) {
    return <LinearProgress />;
  }

  const useLegacyLinks = true;

  const docTemplates = documentTemplatesData.lot_brochure_doc_templates.map(
    template => ({
      id:
        useLegacyLinks && template.id != null
          ? toGlobalId('DocTemplate', template.id)
          : '',
      name: template.name ?? '',
      isDefault: template.is_default ?? false,
      language: template.language ?? 'en',
      isShownInUi: template.is_shown_in_ui ?? true,
      isAdminDefault: template.is_admin_default ?? false,
    }),
  );

  const formDefinition: LightFormDefinition<
    TAvailabilityFormData & TListedByFormData
  > = {
    formDefinition: ({ t, context }) => {
      const availabilityFD = availabilityFormDefinition.formDefinition({
        t,
        context,
      });
      const agentFD = agentFormDefinition.formDefinition({ t, context });

      return [
        ...availabilityFD,
        {
          type: 'custom',
          name: 'brochures',
          gridProps: { xs: 12, sm: 6 },
          element: listing != null && docTemplates.length > 0 && (
            <Stack>
              <FormLabel style={{ marginBottom: '0.5rem', fontWeight: 500 }}>
                {t('Preview brochure')}
              </FormLabel>
              <TemplatesListButtonGroup
                label={t('brochure')}
                docTemplates={docTemplates}
                documentId={
                  useLegacyLinks ? toGlobalId('Lot', listing.id) : listing.id
                }
                disabled={false}
                startIcon={
                  <AutoStoriesOutlinedIcon
                    sx={{ fontSize: '1.5rem !important' }}
                  />
                }
              />
            </Stack>
          ),
        },
        ...agentFD,
      ] as FormFieldDefinitionType<TAvailabilityFormData & TListedByFormData>[];
    },
    transformData: data => {
      const availabilityData = availabilityFormDefinition.transformData(data);
      const agentData = agentFormDefinition.transformData(data);

      return {
        lot: {
          ...(availabilityData.lot ?? {}),
          ...(agentData.lot ?? {}),
        },
        property: {
          ...(availabilityData.property ?? {}),
          ...(agentData.property ?? {}),
        },
      };
    },
    defaultValues: {
      ...availabilityFormDefinition.defaultValues,
      ...agentFormDefinition.defaultValues,
    },
    hasContext: false,
    validate: data => {
      const availabilityErrors =
        availabilityFormDefinition.validate?.(data) ?? [];
      const agentErrors = agentFormDefinition.validate?.(data) ?? [];

      return [...availabilityErrors, ...agentErrors];
    },
  };

  return (
    <RaForm
      formDefinition={formDefinition.formDefinition}
      onSubmit={data =>
        onFormSubmitted({
          action: 'update',
          ...formDefinition.transformData(data),
        })
      }
      defaultValues={formDefinition.defaultValues}
      validate={formDefinition.validate}
      actionButtonsComponent={footerActionsComponent}
      contentScrollable
    />
  );
};
