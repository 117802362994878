import { FormControl, FormHelperText, FormLabel } from '@mui/material';
import type { UseFormGetValues, UseFormSetValue } from 'react-hook-form';

import { LeadSelect } from '../LeadsSelect';

export type RaLeadProps = {
  errors: any;
  getValues: UseFormGetValues<any>;
  setValue: UseFormSetValue<any>;
  name: string;
  label: string;
};
export const RaLead = ({
  getValues,
  setValue,
  errors,
  name,
  label,
}: RaLeadProps) => {
  const lead = getValues(name);
  return (
    <FormControl fullWidth error={!!errors[name]}>
      <FormLabel style={{ marginBottom: '0.5rem', fontWeight: 500 }}>
        {label}
      </FormLabel>
      <LeadSelect
        leadId={lead?.id}
        onChange={lead => setValue(name, lead, { shouldDirty: true })}
      />
      <FormHelperText>{errors[name]?.message}</FormHelperText>
    </FormControl>
  );
};
