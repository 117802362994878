import type { GridProps } from '@mui/material';
import type { FieldPath } from 'react-hook-form';

import type { Translate } from '../../../../src/hooks/locale';
import { getRatingLabel } from '../../../utils/formatting';
import { createNumberFieldElement } from '../../form/RaForm';

export const createDistanceFieldElement = <
  TFormData extends Record<string, any>,
>(
  name: FieldPath<TFormData>,
  label: string,
  gridProps: GridProps = { xs: 6, sm: 6, md: 4 },
) =>
  createNumberFieldElement(name, label, {
    suffix: 'm',
    gridProps,
  });

export const createSurfaceFieldElement = <
  TFormData extends Record<string, any>,
>(
  name: FieldPath<TFormData>,
  label: string,
  render?: (data: TFormData) => boolean,
  gridProps: GridProps = { xs: 6, sm: 6, md: 4 },
) =>
  createNumberFieldElement(name, label, {
    render,
    suffix: 'm²',
    decimalNumbers: 1,
    gridProps,
  });

export const getRatingChoiceOptions = (t: Translate) => {
  const ratings: { value: number | null; label: string }[] = Array.from(
    { length: 5 },
    (_, i) => i + 1,
  ).map(i => ({ value: i, label: getRatingLabel(i, t) ?? i.toString() }));

  ratings.push({ value: null, label: t('notSet') });
  return ratings;
};
