import { gql, useMutation, useQuery } from '@apollo/client';
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useParams } from 'react-router-dom';

import { useLocale } from '../../../src/hooks/locale';
import type { PortalTeamsQuery } from '../../__generated__/graphql';
import { TeamsSelect } from '../../components/teams-select/TeamsSelect';
import { DELETE_TEAM_PORTAL } from '../teams/teamQueries';

const PORTAL_TEAMS = gql`
  query PortalTeams($portalId: uuid!) {
    portals_by_pk(id: $portalId) {
      id
      name
      teams_portals(order_by: { team: { name: asc } }) {
        id
        team {
          id
          name
        }
      }
    }
    portal_listings(
      where: {
        _and: [{ live: { _eq: true } }, { portal_id: { _eq: $portalId } }]
      }
    ) {
      lot {
        broker {
          teams_users {
            team_id
          }
        }
      }
    }
  }
`;

const ADD_PORTAL_TEAM = gql`
  mutation AddPortalTeam($portalId: uuid!, $teamId: uuid!) {
    insert_teams_portals_one(
      object: { portal_id: $portalId, team_id: $teamId }
    ) {
      id
      portal_id
      team_id
    }
  }
`;

export const PortalTeams = () => {
  const { t } = useLocale();
  const { portalId } = useParams();
  const { data } = useQuery<PortalTeamsQuery>(PORTAL_TEAMS, {
    variables: { portalId },
  });

  const refetchQueries = [
    {
      query: PORTAL_TEAMS,
      variables: { portalId },
    },
  ];

  const [addPortalTeam] = useMutation(ADD_PORTAL_TEAM, { refetchQueries });
  const [deleteTeamPortal] = useMutation(DELETE_TEAM_PORTAL, {
    refetchQueries,
  });

  return (
    <Box sx={{ p: 2 }}>
      <Box sx={{ mb: 2, maxWidth: 300 }}>
        <TeamsSelect
          size="small"
          onChange={teamId => {
            if (teamId) {
              addPortalTeam({
                variables: { portalId, teamId },
              });
            }
          }}
          blurOnSelect
          clearOnBlur
          InputProps={{
            placeholder: t('Add team'),
          }}
        />
      </Box>
      <TableContainer component={Paper} variant="outlined">
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>{t('Team')}</TableCell>
              <TableCell width={120}>{t('Listings')}</TableCell>
              <TableCell align="center" width={80}>
                {t('Actions')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.portals_by_pk?.teams_portals.map(tp => (
              <TableRow key={tp.team.id}>
                <TableCell>{tp.team.name}</TableCell>
                <TableCell align="right">
                  {
                    data?.portal_listings.filter(
                      pl =>
                        pl.lot.broker?.teams_users.find(
                          tu => tu.team_id === tp.team.id,
                        ) !== undefined,
                    ).length
                  }
                </TableCell>
                <TableCell align="center">
                  <Button
                    disableElevation
                    disabled={
                      data?.portal_listings.filter(
                        pl =>
                          pl.lot.broker?.teams_users.find(
                            tu => tu.team_id === tp.team.id,
                          ) !== undefined,
                      ).length > 0
                    }
                    color="error"
                    size="small"
                    onClick={() =>
                      deleteTeamPortal({ variables: { id: tp.id } })
                    }
                  >
                    {t('Remove')}
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
