import Timeline from '@mui/lab/Timeline';
import { timelineItemClasses } from '@mui/lab/TimelineItem';
import { Alert, Typography } from '@mui/material';

import type { ActivityFieldsFragment } from '../../__generated__/graphql';

import { Activity } from './Activity';

interface FeedProps {
  activities?: ActivityFieldsFragment[];
}

export const Feed = ({ activities }: FeedProps) => {
  if (!activities?.length) {
    return (
      <Alert severity="info">
        <Typography variant="body2">No activities found</Typography>
      </Alert>
    );
  }

  return (
    <Timeline
      sx={{
        p: 0,
        maxWidth: 750,
        [`& .${timelineItemClasses.root}:before`]: {
          flex: 0,
          padding: 0,
        },
      }}
    >
      {activities?.map(activity => (
        <Activity key={activity?.id} activity={activity} />
      ))}
    </Timeline>
  );
};
