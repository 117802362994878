import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { AddLocationAltOutlined, HomeWorkOutlined } from '@mui/icons-material';
import { Alert } from '@mui/material';
import { type ErrorOption } from 'react-hook-form';
import {
  Navigate,
  Route,
  Routes,
  useMatch,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';

import { useLocale } from '../../../src/hooks/locale';
import { gql } from '../../__generated__';
import type {
  CreateTransactionMutation,
  GetPropertyTypesQuery,
  GetUserDefaultTeamIdQuery,
} from '../../__generated__/graphql';
import { Drawer } from '../../components/drawer/Drawer';
import {
  type DrawerTab,
  generateTab,
} from '../../components/drawer/DrawerTopBar';
import {
  type FormDefinitionType,
  RaForm,
  createCategoryElement,
} from '../../components/form/RaForm';
import { createPropertyTypeOptions } from '../../components/property-form/forms-definitions/propertyDetailsFormDefinition';
import { useAppData } from '../../providers/AppDataProvider';
import { isHouseOrAppt } from '../../utils/propertyDataChecks';
import { numberContainsDecimals } from '../../utils/validation';

import {
  CREATE_TRANSACTION,
  GET_USER_DEFAULT_TEAM_ID,
} from './transactionQueries';

const GET_PROPERTY_TYPES = gql(`
  query getPropertyTypes {
    property_types {
      id
      name
      label
      main_type
    }
  }
`);

const fromLotFormDefition: FormDefinitionType<
  Record<string, any>,
  { property_types: GetPropertyTypesQuery['property_types'] }
> = ({ t }) => [
  {
    label: t('Listing'),
    type: 'lot',
    name: 'lot_id',
  },
  createCategoryElement('stakeholders', t('Stakeholders')),
  {
    name: 'seller_broker_id',
    label: t('Seller agent'),
    type: 'user',
    required: true,
  },
  {
    name: 'seller_broker_team_id',
    label: t('Seller agent team'),
    type: 'team',
  },
  {
    name: 'buyer_broker_id',
    label: t('Buyer agent'),
    type: 'user',
    required: true,
  },
  {
    name: 'buyer_broker_team_id',
    label: t('Buyer agent team'),
    type: 'team',
  },
];

const isHouse = (main_type: any) => main_type === 'HOUSE';

const formDefinition: FormDefinitionType<
  Record<string, any>,
  {
    property_types: GetPropertyTypesQuery['property_types'];
    isTenantSpanish: boolean;
  }
> = ({ t, context }) => [
  createCategoryElement('address', t('Address')),
  {
    path: 'property',
    label: t('Name'),
    type: 'address',
    // required: true, This is not how I would really like it but no in this PR
    gridProps: { xs: 12, md: 12, lg: 12, xl: 12 },
  },
  createCategoryElement('transaction', t('Transaction')),
  {
    name: 'signature_date',
    label: t('Signing date'),
    type: 'date',
    required: true,
  },
  {
    name: 'purchase_price',
    label: t('Purchase price'),
    type: 'number',
    required: true,
    max: 1000000000,
  },
  createCategoryElement('stakeholders', t('Stakeholders')),
  {
    name: 'seller_broker_id',
    label: t('Seller agent'),
    type: 'user',
    required: true,
  },
  {
    name: 'seller_broker_team_id',
    label: t('Seller agent team'),
    type: 'team',
  },
  {
    name: 'buyer_broker_id',
    label: t('Buyer agent'),
    type: 'user',
  },
  {
    name: 'buyer_broker_team_id',
    label: t('Buyer agent team'),
    type: 'team',
  },
  createCategoryElement('property_type_title', t('Property type')),
  {
    name: '__main_type',
    label: t('Property main type'),
    type: 'select',
    options: () =>
      createPropertyTypeOptions(
        context.property_types,
        pt => pt.main_type == null,
        'name',
      ),
    required: true,
  },
  {
    name: 'property.property_type_id',
    label: t('Property type'),
    type: 'select',
    options: ({ __main_type }) =>
      createPropertyTypeOptions(
        context.property_types,
        pt => pt.main_type === __main_type,
        'id',
      ),
    required: true,
  },
  {
    name: context.isTenantSpanish
      ? 'property.number_of_bedrooms'
      : 'property.number_of_rooms',
    label: context.isTenantSpanish
      ? t('Number of bedrooms')
      : t('Number of rooms'),
    type: 'number',
    decimalNumbers: 1,
    render: ({ __main_type, property }) => {
      const propertyTypeName = context.property_types.find(
        pt => pt.id === property.property_type_id,
      )?.name;

      return isHouseOrAppt(__main_type, propertyTypeName);
    },
    required: true,
    min: 1,
  },
  {
    name: context.isTenantSpanish
      ? 'property.built_surface'
      : 'property.living_surface',
    label: context.isTenantSpanish ? t('Built surface') : t('Living surface'),
    type: 'number',
    render: ({ __main_type }) => isHouseOrAppt(__main_type),
    required: true,
    min: 0,
  },
  {
    name: 'property.land_surface',
    label: t('Land surface'),
    type: 'number',
    render: ({ __main_type }) => isHouse(__main_type),
    required: true,
    min: 0,
  },
];

export const TransactionCreateDrawer = () => {
  const { me } = useAppData();
  const navigate = useNavigate();
  const { t } = useLocale();
  const [searchParams] = useSearchParams();
  const [createTransaction] =
    useMutation<CreateTransactionMutation>(CREATE_TRANSACTION);

  const [getTeam] = useLazyQuery<GetUserDefaultTeamIdQuery>(
    GET_USER_DEFAULT_TEAM_ID,
  );

  const { data, error } = useQuery(GET_PROPERTY_TYPES);
  const property_types = data?.property_types ?? [];

  const match = useMatch('/v2/transactions/new/:tabName/*');
  const defaultListingId = searchParams.get('selected-listing');

  const tabsDef: [string, string, React.ReactElement?][] = [
    [t('From scratch'), 'scratch', <AddLocationAltOutlined />],
    [t('From listing'), 'listing', <HomeWorkOutlined />],
  ];

  const tabs: DrawerTab[] = tabsDef.map(([label, value, icon]) =>
    generateTab([searchParams.toString(), label, value, icon]),
  );

  const handleClose = () =>
    navigate({
      pathname: '../',
      search: searchParams.toString(),
    });

  const handleSubmit = (values: any) => {
    const { property, ...transaction } = values;
    return createTransaction({
      variables: {
        transaction: {
          ...transaction,
          property: {
            data: {
              ...property,
            },
          },
        },
      },
    }).then(res => {
      navigate({
        pathname: `../${res.data?.insert_property_transactions_one?.id}/transaction`,
        search: searchParams.toString(),
      });
    });
  };

  return (
    <Drawer
      onClose={handleClose}
      currentTab={match?.params.tabName ?? 'scratch'}
      title={t('Create transaction')}
      tabs={tabs}
    >
      {error && (
        <Alert severity="error" sx={{ m: 2 }}>
          <pre>{JSON.stringify(error, null, 2)}</pre>
        </Alert>
      )}
      <Routes>
        <Route index element={<Navigate to="scratch" />} />
        <Route
          path="scratch"
          element={
            <RaForm
              formDefinition={formDefinition}
              onSubmit={handleSubmit}
              defaultValues={{ property: {} }}
              validate={values => {
                const errors: ['property.number_of_rooms', ErrorOption][] = [];

                if (values.property.number_of_rooms != null) {
                  if (
                    !numberContainsDecimals(
                      values.property.number_of_rooms,
                      [0, 5],
                    )
                  ) {
                    errors.push([
                      'property.number_of_rooms',
                      {
                        message: t(
                          'The number of rooms must be a 0.5 increment',
                        ),
                      },
                    ]);
                  }
                }

                return errors;
              }}
              context={{
                property_types,
                isTenantSpanish: me?.tenant.country_code === 'ES',
              }}
              onChange={(data, name, setValue) => {
                // set seller_broker_team_id if seller_broker_id is changed
                if (
                  name === 'seller_broker_id' &&
                  data?.seller_broker_team_id == null &&
                  data?.seller_broker_id != null
                ) {
                  getTeam({ variables: { id: data.seller_broker_id } }).then(
                    response =>
                      setValue(
                        'seller_broker_team_id',
                        response?.data?.users_by_pk?.default_team_id,
                        {
                          shouldValidate: true,
                          shouldDirty: true,
                        },
                      ),
                  );
                }
                // set buyer_broker_team_id if buyer_broker_id is changed
                if (
                  name === 'buyer_broker_id' &&
                  data?.buyer_broker_team_id == null &&
                  data?.buyer_broker_id != null
                ) {
                  getTeam({ variables: { id: data.buyer_broker_id } }).then(
                    response =>
                      setValue(
                        'buyer_broker_team_id',
                        response?.data?.users_by_pk?.default_team_id,
                        {
                          shouldValidate: true,
                          shouldDirty: true,
                        },
                      ),
                  );
                }
              }}
            />
          }
        />
        <Route
          path="listing"
          element={
            <RaForm
              formDefinition={fromLotFormDefition}
              onSubmit={handleSubmit}
              context={{
                property_types,
              }}
              defaultValues={{
                lot_id: defaultListingId,
              }}
            />
          }
        />
      </Routes>
    </Drawer>
  );
};

export default TransactionCreateDrawer;
